var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import warning from '../_util/warning';
import Base, { baseProps } from './Base';
import PropTypes from '../_util/vue-types';
import omit from '../_util/omit';
const Link = (props, { slots, attrs }) => {
    const _a = Object.assign(Object.assign({}, props), attrs), { ellipsis, rel } = _a, restProps = __rest(_a, ["ellipsis", "rel"]);
    warning(typeof ellipsis !== 'object', 'Typography.Link', '`ellipsis` only supports boolean value.');
    const mergedProps = Object.assign(Object.assign({}, restProps), { rel: rel === undefined && restProps.target === '_blank' ? 'noopener noreferrer' : rel, ellipsis: !!ellipsis, component: 'a' });
    // https://github.com/ant-design/ant-design/issues/26622
    // @ts-ignore
    delete mergedProps.navigate;
    return <Base {...mergedProps} v-slots={slots}></Base>;
};
Link.displayName = 'ATypographyLink';
Link.inheritAttrs = false;
Link.props = omit(Object.assign(Object.assign({}, baseProps()), { ellipsis: PropTypes.looseBool }), ['component']);
export default Link;
