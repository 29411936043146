import { tuple } from '../_util/type';
import PropsTypes from '../_util/vue-types';
export const UploadFileStatus = PropsTypes.oneOf(tuple('error', 'success', 'done', 'uploading', 'removed'));
export const ShowUploadListInterface = PropsTypes.shape({
    showRemoveIcon: PropsTypes.looseBool,
    showPreviewIcon: PropsTypes.looseBool,
}).loose;
export const uploadProps = {
    type: PropsTypes.oneOf(tuple('drag', 'select')),
    name: PropsTypes.string,
    defaultFileList: { type: Array },
    fileList: { type: Array },
    action: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.func]),
    directory: PropsTypes.looseBool,
    data: PropsTypes.oneOfType([PropsTypes.object, PropsTypes.func]),
    method: PropsTypes.oneOf(tuple('POST', 'PUT', 'PATCH', 'post', 'put', 'patch')),
    headers: PropsTypes.object,
    showUploadList: PropsTypes.oneOfType([PropsTypes.looseBool, ShowUploadListInterface]),
    multiple: PropsTypes.looseBool,
    accept: PropsTypes.string,
    beforeUpload: PropsTypes.func,
    listType: PropsTypes.oneOf(tuple('text', 'picture', 'picture-card')),
    // className: PropsTypes.string,
    remove: PropsTypes.func,
    supportServerRender: PropsTypes.looseBool,
    // style: PropsTypes.object,
    disabled: PropsTypes.looseBool,
    prefixCls: PropsTypes.string,
    customRequest: PropsTypes.func,
    withCredentials: PropsTypes.looseBool,
    openFileDialogOnClick: PropsTypes.looseBool,
    locale: { type: Object },
    height: PropsTypes.number,
    id: PropsTypes.string,
    previewFile: PropsTypes.func,
    transformFile: PropsTypes.func,
    onChange: { type: Function },
    onPreview: { type: Function },
    onRemove: {
        type: Function,
    },
    onDownload: { type: Function },
    'onUpdate:fileList': { type: Function },
};
export const uploadListProps = {
    listType: PropsTypes.oneOf(tuple('text', 'picture', 'picture-card')),
    // items: PropsTypes.arrayOf(UploadFile),
    items: { type: Array },
    progressAttr: PropsTypes.object,
    prefixCls: PropsTypes.string,
    showRemoveIcon: PropsTypes.looseBool,
    showDownloadIcon: PropsTypes.looseBool,
    showPreviewIcon: PropsTypes.looseBool,
    locale: { type: Object },
    previewFile: PropsTypes.func,
    onPreview: { type: Function },
    onRemove: {
        type: Function,
    },
    onDownload: { type: Function },
};
