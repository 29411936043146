import { selectBaseProps } from '../vc-select';
import omit from '../_util/omit';
import PropTypes from '../_util/vue-types';
export function optionListProps() {
    return {
        prefixCls: String,
        id: String,
        options: { type: Array },
        flattenOptions: { type: Array },
        height: Number,
        itemHeight: Number,
        virtual: { type: Boolean, default: undefined },
        values: { type: Set },
        multiple: { type: Boolean, default: undefined },
        open: { type: Boolean, default: undefined },
        defaultActiveFirstOption: { type: Boolean, default: undefined },
        notFoundContent: PropTypes.any,
        menuItemSelectedIcon: PropTypes.any,
        childrenAsData: { type: Boolean, default: undefined },
        searchValue: String,
        onSelect: {
            type: Function,
        },
        onToggleOpen: { type: Function },
        /** Tell Select that some value is now active to make accessibility work */
        onActiveValue: { type: Function },
        onScroll: { type: Function },
        onMouseenter: { type: Function },
    };
}
export function treeSelectProps() {
    const selectProps = omit(selectBaseProps(), [
        'onChange',
        'mode',
        'menuItemSelectedIcon',
        'dropdownAlign',
        'backfill',
        'getInputElement',
        'optionLabelProp',
        'tokenSeparators',
        'filterOption',
    ]);
    return Object.assign(Object.assign({}, selectProps), { multiple: { type: Boolean, default: undefined }, showArrow: { type: Boolean, default: undefined }, showSearch: { type: Boolean, default: undefined }, open: { type: Boolean, default: undefined }, defaultOpen: { type: Boolean, default: undefined }, value: { type: [String, Number, Object, Array] }, defaultValue: { type: [String, Number, Object, Array] }, disabled: { type: Boolean, default: undefined }, placeholder: PropTypes.any, 
        /** @deprecated Use `searchValue` instead */
        inputValue: String, searchValue: String, autoClearSearchValue: { type: Boolean, default: undefined }, maxTagPlaceholder: { type: Function }, fieldNames: { type: Object }, loadData: { type: Function }, treeNodeFilterProp: String, treeNodeLabelProp: String, treeDataSimpleMode: {
            type: [Boolean, Object],
            default: undefined,
        }, treeExpandedKeys: { type: Array }, treeDefaultExpandedKeys: { type: Array }, treeLoadedKeys: { type: Array }, treeCheckable: { type: Boolean, default: undefined }, treeCheckStrictly: { type: Boolean, default: undefined }, showCheckedStrategy: { type: String }, treeDefaultExpandAll: { type: Boolean, default: undefined }, treeData: { type: Array }, treeLine: { type: Boolean, default: undefined }, treeIcon: PropTypes.any, showTreeIcon: { type: Boolean, default: undefined }, switcherIcon: PropTypes.any, treeMotion: PropTypes.any, children: Array, filterTreeNode: {
            type: [Boolean, Function],
            default: undefined,
        }, dropdownPopupAlign: PropTypes.any, 
        // Event
        onSearch: { type: Function }, onChange: {
            type: Function,
        }, onTreeExpand: { type: Function }, onTreeLoad: { type: Function }, onDropdownVisibleChange: { type: Function }, 
        // Legacy
        /** `searchPlaceholder` has been removed since search box has been merged into input box */
        searchPlaceholder: PropTypes.any, 
        /** @private This is not standard API since we only used in `rc-cascader`. Do not use in your production */
        labelRender: { type: Function } });
}
class Helper {
    constructor() {
        this.ReturnOptionListProps = optionListProps();
        this.ReturnTreeSelectProps = treeSelectProps();
    }
}
