import RcTable from '../vc-table';
import { INTERNAL_HOOKS } from '../vc-table/Table';
import Spin from '../spin';
import Pagination from '../pagination';
import usePagination, { DEFAULT_PAGE_SIZE, getPaginationParam } from './hooks/usePagination';
import useLazyKVMap from './hooks/useLazyKVMap';
import useSelection from './hooks/useSelection';
import useSorter, { getSortData } from './hooks/useSorter';
import useFilter, { getFilterData } from './hooks/useFilter';
import useTitleColumns from './hooks/useTitleColumns';
import renderExpandIcon from './ExpandIcon';
import scrollTo from '../_util/scrollTo';
import defaultLocale from '../locale/en_US';
import devWarning from '../vc-util/devWarning';
import { nextTick, reactive, ref, computed, defineComponent, toRef, watchEffect, watch } from 'vue';
import useBreakpoint from '../_util/hooks/useBreakpoint';
import useConfigInject from '../_util/hooks/useConfigInject';
import { useLocaleReceiver } from '../locale-provider/LocaleReceiver';
import classNames from '../_util/classNames';
import omit from '../_util/omit';
import { initDefaultProps } from '../_util/props-util';
import { useProvideSlots, useProvideTableContext } from './context';
import useColumns from './hooks/useColumns';
import { convertChildrenToColumns } from './util';
const EMPTY_LIST = [];
export const tableProps = () => {
    return {
        prefixCls: { type: String, default: undefined },
        columns: { type: Array, default: undefined },
        rowKey: { type: [String, Function], default: undefined },
        tableLayout: { type: String, default: undefined },
        rowClassName: {
            type: [String, Function],
            default: undefined,
        },
        title: { type: Function, default: undefined },
        footer: { type: Function, default: undefined },
        id: { type: String, default: undefined },
        showHeader: { type: Boolean, default: undefined },
        components: { type: Object, default: undefined },
        customRow: { type: Function, default: undefined },
        customHeaderRow: {
            type: Function,
            default: undefined,
        },
        direction: { type: String, default: undefined },
        expandFixed: { type: Boolean, default: undefined },
        expandColumnWidth: {
            type: Number,
            default: undefined,
        },
        expandedRowKeys: {
            type: Array,
            default: undefined,
        },
        defaultExpandedRowKeys: {
            type: Array,
            default: undefined,
        },
        expandedRowRender: {
            type: Function,
            default: undefined,
        },
        expandRowByClick: {
            type: Boolean,
            default: undefined,
        },
        expandIcon: { type: Function, default: undefined },
        onExpand: { type: Function, default: undefined },
        onExpandedRowsChange: {
            type: Function,
            default: undefined,
        },
        defaultExpandAllRows: {
            type: Boolean,
            default: undefined,
        },
        indentSize: { type: Number, default: undefined },
        expandIconColumnIndex: {
            type: Number,
            default: undefined,
        },
        expandedRowClassName: {
            type: Function,
            default: undefined,
        },
        childrenColumnName: {
            type: String,
            default: undefined,
        },
        rowExpandable: { type: Function, default: undefined },
        sticky: { type: [Boolean, Object], default: undefined },
        dropdownPrefixCls: String,
        dataSource: { type: Array, default: undefined },
        pagination: {
            type: [Boolean, Object],
            default: undefined,
        },
        loading: { type: [Boolean, Object], default: undefined },
        size: { type: String, default: undefined },
        bordered: Boolean,
        locale: { type: Object, default: undefined },
        onChange: {
            type: Function,
            default: undefined,
        },
        onResizeColumn: {
            type: Function,
            default: undefined,
        },
        rowSelection: { type: Object, default: undefined },
        getPopupContainer: { type: Function, default: undefined },
        scroll: {
            type: Object,
            default: undefined,
        },
        sortDirections: { type: Array, default: undefined },
        showSorterTooltip: {
            type: [Boolean, Object],
            default: true,
        },
        contextSlots: {
            type: Object,
        },
        transformCellText: {
            type: Function,
        },
    };
};
const InteralTable = defineComponent({
    name: 'InteralTable',
    inheritAttrs: false,
    props: initDefaultProps(tableProps(), {
        rowKey: 'key',
    }),
    // emits: ['expandedRowsChange', 'change', 'expand'],
    slots: [
        'emptyText',
        'expandIcon',
        'title',
        'footer',
        'summary',
        'expandedRowRender',
        'bodyCell',
        'headerCell',
        'customFilterIcon',
        'customFilterDropdown',
    ],
    setup(props, { attrs, slots, expose, emit }) {
        devWarning(!(typeof props.rowKey === 'function' && props.rowKey.length > 1), 'Table', '`index` parameter of `rowKey` function is deprecated. There is no guarantee that it will work as expected.');
        useProvideSlots(computed(() => props.contextSlots));
        useProvideTableContext({
            onResizeColumn: (w, col) => {
                emit('resizeColumn', w, col);
            },
        });
        const screens = useBreakpoint();
        const mergedColumns = computed(() => {
            const matched = new Set(Object.keys(screens).filter((m) => screens[m]));
            return props.columns.filter((c) => !c.responsive || c.responsive.some((r) => matched.has(r)));
        });
        const { size: mergedSize, renderEmpty, direction, prefixCls, configProvider, } = useConfigInject('table', props);
        const transformCellText = computed(() => props.transformCellText || configProvider.transformCellText);
        const [tableLocale] = useLocaleReceiver('Table', defaultLocale.Table, toRef(props, 'locale'));
        const rawData = computed(() => props.dataSource || EMPTY_LIST);
        const dropdownPrefixCls = computed(() => configProvider.getPrefixCls('dropdown', props.dropdownPrefixCls));
        const childrenColumnName = computed(() => props.childrenColumnName || 'children');
        const expandType = computed(() => {
            if (rawData.value.some(item => { var _a; return (_a = item) === null || _a === void 0 ? void 0 : _a[childrenColumnName.value]; })) {
                return 'nest';
            }
            if (props.expandedRowRender) {
                return 'row';
            }
            return null;
        });
        const internalRefs = reactive({
            body: null,
        });
        const updateInternalRefs = refs => {
            Object.assign(internalRefs, refs);
        };
        // ============================ RowKey ============================
        const getRowKey = computed(() => {
            if (typeof props.rowKey === 'function') {
                return props.rowKey;
            }
            return record => { var _a; return (_a = record) === null || _a === void 0 ? void 0 : _a[props.rowKey]; };
        });
        const [getRecordByKey] = useLazyKVMap(rawData, childrenColumnName, getRowKey);
        // ============================ Events =============================
        const changeEventInfo = {};
        const triggerOnChange = (info, action, reset = false) => {
            const { pagination, scroll, onChange } = props;
            const changeInfo = Object.assign(Object.assign({}, changeEventInfo), info);
            if (reset) {
                changeEventInfo.resetPagination();
                // Reset event param
                if (changeInfo.pagination.current) {
                    changeInfo.pagination.current = 1;
                }
                // Trigger pagination events
                if (pagination && pagination.onChange) {
                    pagination.onChange(1, changeInfo.pagination.pageSize);
                }
            }
            if (scroll && scroll.scrollToFirstRowOnChange !== false && internalRefs.body) {
                scrollTo(0, {
                    getContainer: () => internalRefs.body,
                });
            }
            onChange === null || onChange === void 0 ? void 0 : onChange(changeInfo.pagination, changeInfo.filters, changeInfo.sorter, {
                currentDataSource: getFilterData(getSortData(rawData.value, changeInfo.sorterStates, childrenColumnName.value), changeInfo.filterStates),
                action,
            });
        };
        /**
         * Controlled state in `columns` is not a good idea that makes too many code (1000+ line?) to read
         * state out and then put it back to title render. Move these code into `hooks` but still too
         * complex. We should provides Table props like `sorter` & `filter` to handle control in next big version.
         */
        // ============================ Sorter =============================
        const onSorterChange = (sorter, sorterStates) => {
            triggerOnChange({
                sorter,
                sorterStates,
            }, 'sort', false);
        };
        const [transformSorterColumns, sortStates, sorterTitleProps, sorters] = useSorter({
            prefixCls,
            mergedColumns,
            onSorterChange,
            sortDirections: computed(() => props.sortDirections || ['ascend', 'descend']),
            tableLocale,
            showSorterTooltip: toRef(props, 'showSorterTooltip'),
        });
        const sortedData = computed(() => getSortData(rawData.value, sortStates.value, childrenColumnName.value));
        // ============================ Filter ============================
        const onFilterChange = (filters, filterStates) => {
            triggerOnChange({
                filters,
                filterStates,
            }, 'filter', true);
        };
        const [transformFilterColumns, filterStates, filters] = useFilter({
            prefixCls,
            locale: tableLocale,
            dropdownPrefixCls,
            mergedColumns,
            onFilterChange,
            getPopupContainer: toRef(props, 'getPopupContainer'),
        });
        const mergedData = computed(() => getFilterData(sortedData.value, filterStates.value));
        // ============================ Column ============================
        const [transformBasicColumns] = useColumns(toRef(props, 'contextSlots'));
        const columnTitleProps = computed(() => (Object.assign({}, sorterTitleProps.value)));
        const [transformTitleColumns] = useTitleColumns(columnTitleProps);
        // ========================== Pagination ==========================
        const onPaginationChange = (current, pageSize) => {
            triggerOnChange({
                pagination: Object.assign(Object.assign({}, changeEventInfo.pagination), { current, pageSize }),
            }, 'paginate');
        };
        const [mergedPagination, resetPagination] = usePagination(computed(() => mergedData.value.length), toRef(props, 'pagination'), onPaginationChange);
        watchEffect(() => {
            changeEventInfo.sorter = sorters.value;
            changeEventInfo.sorterStates = sortStates.value;
            changeEventInfo.filters = filters.value;
            changeEventInfo.filterStates = filterStates.value;
            changeEventInfo.pagination =
                props.pagination === false
                    ? {}
                    : getPaginationParam(props.pagination, mergedPagination.value);
            changeEventInfo.resetPagination = resetPagination;
        });
        // ============================= Data =============================
        const pageData = computed(() => {
            if (props.pagination === false || !mergedPagination.value.pageSize) {
                return mergedData.value;
            }
            const { current = 1, total, pageSize = DEFAULT_PAGE_SIZE } = mergedPagination.value;
            devWarning(current > 0, 'Table', '`current` should be positive number.');
            // Dynamic table data
            if (mergedData.value.length < total) {
                if (mergedData.value.length > pageSize) {
                    return mergedData.value.slice((current - 1) * pageSize, current * pageSize);
                }
                return mergedData.value;
            }
            return mergedData.value.slice((current - 1) * pageSize, current * pageSize);
        });
        watchEffect(() => {
            nextTick(() => {
                const { total, pageSize = DEFAULT_PAGE_SIZE } = mergedPagination.value;
                // Dynamic table data
                if (mergedData.value.length < total) {
                    if (mergedData.value.length > pageSize) {
                        devWarning(false, 'Table', '`dataSource` length is less than `pagination.total` but large than `pagination.pageSize`. Please make sure your config correct data with async mode.');
                    }
                }
            });
        }, { flush: 'post' });
        const expandIconColumnIndex = computed(() => {
            // Adjust expand icon index, no overwrite expandIconColumnIndex if set.
            if (expandType.value === 'nest' && props.expandIconColumnIndex === undefined) {
                return props.rowSelection ? 1 : 0;
            }
            else if (props.expandIconColumnIndex > 0 && props.rowSelection) {
                return props.expandIconColumnIndex - 1;
            }
            return props.expandIconColumnIndex;
        });
        const rowSelection = ref();
        watch(() => props.rowSelection, () => {
            rowSelection.value = props.rowSelection ? Object.assign({}, props.rowSelection) : props.rowSelection;
        }, { deep: true, immediate: true });
        // ========================== Selections ==========================
        const [transformSelectionColumns, selectedKeySet] = useSelection(rowSelection, {
            prefixCls,
            data: mergedData,
            pageData,
            getRowKey,
            getRecordByKey,
            expandType,
            childrenColumnName,
            locale: tableLocale,
            expandIconColumnIndex,
            getPopupContainer: computed(() => props.getPopupContainer),
        });
        const internalRowClassName = (record, index, indent) => {
            let mergedRowClassName;
            const { rowClassName } = props;
            if (typeof rowClassName === 'function') {
                mergedRowClassName = classNames(rowClassName(record, index, indent));
            }
            else {
                mergedRowClassName = classNames(rowClassName);
            }
            return classNames({
                [`${prefixCls.value}-row-selected`]: selectedKeySet.value.has(getRowKey.value(record, index)),
            }, mergedRowClassName);
        };
        expose({
            selectedKeySet,
        });
        const indentSize = computed(() => {
            // Indent size
            return typeof props.indentSize === 'number' ? props.indentSize : 15;
        });
        const transformColumns = (innerColumns) => {
            const res = transformTitleColumns(transformSelectionColumns(transformFilterColumns(transformSorterColumns(transformBasicColumns(innerColumns)))));
            return res;
        };
        return () => {
            var _a;
            const { expandIcon = slots.expandIcon || renderExpandIcon(tableLocale.value), pagination, loading, bordered, } = props;
            let topPaginationNode;
            let bottomPaginationNode;
            if (pagination !== false && ((_a = mergedPagination.value) === null || _a === void 0 ? void 0 : _a.total)) {
                let paginationSize;
                if (mergedPagination.value.size) {
                    paginationSize = mergedPagination.value.size;
                }
                else {
                    paginationSize =
                        mergedSize.value === 'small' || mergedSize.value === 'middle' ? 'small' : undefined;
                }
                const renderPagination = (position) => (<Pagination class={`${prefixCls.value}-pagination ${prefixCls.value}-pagination-${position}`} {...mergedPagination.value} size={paginationSize}/>);
                const defaultPosition = direction.value === 'rtl' ? 'left' : 'right';
                const { position } = mergedPagination.value;
                if (position !== null && Array.isArray(position)) {
                    const topPos = position.find(p => p.indexOf('top') !== -1);
                    const bottomPos = position.find(p => p.indexOf('bottom') !== -1);
                    const isDisable = position.every(p => `${p}` === 'none');
                    if (!topPos && !bottomPos && !isDisable) {
                        bottomPaginationNode = renderPagination(defaultPosition);
                    }
                    if (topPos) {
                        topPaginationNode = renderPagination(topPos.toLowerCase().replace('top', ''));
                    }
                    if (bottomPos) {
                        bottomPaginationNode = renderPagination(bottomPos.toLowerCase().replace('bottom', ''));
                    }
                }
                else {
                    bottomPaginationNode = renderPagination(defaultPosition);
                }
            }
            // >>>>>>>>> Spinning
            let spinProps;
            if (typeof loading === 'boolean') {
                spinProps = {
                    spinning: loading,
                };
            }
            else if (typeof loading === 'object') {
                spinProps = Object.assign({ spinning: true }, loading);
            }
            const wrapperClassNames = classNames(`${prefixCls.value}-wrapper`, {
                [`${prefixCls.value}-wrapper-rtl`]: direction.value === 'rtl',
            }, attrs.class);
            const tableProps = omit(props, ['columns']);
            return (<div class={wrapperClassNames} style={attrs.style}>
          <Spin spinning={false} {...spinProps}>
            {topPaginationNode}
            <RcTable {...attrs} {...tableProps} expandedRowKeys={props.expandedRowKeys} defaultExpandedRowKeys={props.defaultExpandedRowKeys} expandIconColumnIndex={expandIconColumnIndex.value} indentSize={indentSize.value} expandIcon={expandIcon} columns={mergedColumns.value} direction={direction.value} prefixCls={prefixCls.value} class={classNames({
                    [`${prefixCls.value}-middle`]: mergedSize.value === 'middle',
                    [`${prefixCls.value}-small`]: mergedSize.value === 'small',
                    [`${prefixCls.value}-bordered`]: bordered,
                    [`${prefixCls.value}-empty`]: rawData.value.length === 0,
                })} data={pageData.value} rowKey={getRowKey.value} rowClassName={internalRowClassName} 
            // Internal
            internalHooks={INTERNAL_HOOKS} internalRefs={internalRefs} onUpdateInternalRefs={updateInternalRefs} transformColumns={transformColumns} transformCellText={transformCellText.value} v-slots={Object.assign(Object.assign({}, slots), { emptyText: () => { var _a, _b; return ((_a = slots.emptyText) === null || _a === void 0 ? void 0 : _a.call(slots)) || ((_b = props.locale) === null || _b === void 0 ? void 0 : _b.emptyText) || renderEmpty.value('Table'); } })}/>
            {bottomPaginationNode}
          </Spin>
        </div>);
        };
    },
});
const Table = defineComponent({
    name: 'ATable',
    inheritAttrs: false,
    setup(_props, { attrs, slots, expose }) {
        const table = ref();
        expose({
            table,
        });
        return () => {
            var _a;
            const props = attrs;
            const columns = props.columns || convertChildrenToColumns((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots));
            return (<InteralTable ref={table} {...attrs} columns={columns || []} expandedRowRender={slots.expandedRowRender} contextSlots={Object.assign({}, slots)} // use new object, 否则slot热更新失效，原因需进一步探究
             v-slots={slots}/>);
        };
    },
});
export default Table;
