var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Trigger from '../vc-trigger';
import PropTypes from '../_util/vue-types';
import { getSlot } from '../_util/props-util';
import classNames from '../_util/classNames';
import createRef from '../_util/createRef';
import { defineComponent } from 'vue';
const getBuiltInPlacements = (dropdownMatchSelectWidth) => {
    // Enable horizontal overflow auto-adjustment when a custom dropdown width is provided
    const adjustX = typeof dropdownMatchSelectWidth !== 'number' ? 0 : 1;
    return {
        bottomLeft: {
            points: ['tl', 'bl'],
            offset: [0, 4],
            overflow: {
                adjustX,
                adjustY: 1,
            },
        },
        bottomRight: {
            points: ['tr', 'br'],
            offset: [0, 4],
            overflow: {
                adjustX,
                adjustY: 1,
            },
        },
        topLeft: {
            points: ['bl', 'tl'],
            offset: [0, -4],
            overflow: {
                adjustX,
                adjustY: 1,
            },
        },
        topRight: {
            points: ['br', 'tr'],
            offset: [0, -4],
            overflow: {
                adjustX,
                adjustY: 1,
            },
        },
    };
};
const SelectTrigger = defineComponent({
    name: 'SelectTrigger',
    inheritAttrs: false,
    created() {
        this.popupRef = createRef();
    },
    methods: {
        getPopupElement() {
            return this.popupRef.current;
        },
    },
    render() {
        const _a = Object.assign(Object.assign({}, this.$props), this.$attrs), { empty = false } = _a, props = __rest(_a, ["empty"]);
        const { visible, dropdownAlign, prefixCls, popupElement, dropdownClassName, dropdownStyle, direction = 'ltr', placement, dropdownMatchSelectWidth, containerWidth, dropdownRender, animation, transitionName, getPopupContainer, getTriggerDOMNode, } = props;
        const dropdownPrefixCls = `${prefixCls}-dropdown`;
        let popupNode = popupElement;
        if (dropdownRender) {
            popupNode = dropdownRender({ menuNode: popupElement, props });
        }
        const builtInPlacements = getBuiltInPlacements(dropdownMatchSelectWidth);
        const mergedTransitionName = animation ? `${dropdownPrefixCls}-${animation}` : transitionName;
        const popupStyle = Object.assign({ minWidth: `${containerWidth}px` }, dropdownStyle);
        if (typeof dropdownMatchSelectWidth === 'number') {
            popupStyle.width = `${dropdownMatchSelectWidth}px`;
        }
        else if (dropdownMatchSelectWidth) {
            popupStyle.width = `${containerWidth}px`;
        }
        return (<Trigger {...props} showAction={[]} hideAction={[]} popupPlacement={placement || (direction === 'rtl' ? 'bottomRight' : 'bottomLeft')} builtinPlacements={builtInPlacements} prefixCls={dropdownPrefixCls} popupTransitionName={mergedTransitionName} popup={<div ref={this.popupRef}>{popupNode}</div>} popupAlign={dropdownAlign} popupVisible={visible} getPopupContainer={getPopupContainer} popupClassName={classNames(dropdownClassName, {
                [`${dropdownPrefixCls}-empty`]: empty,
            })} popupStyle={popupStyle} getTriggerDOMNode={getTriggerDOMNode}>
        {getSlot(this)[0]}
      </Trigger>);
    },
});
SelectTrigger.props = {
    dropdownAlign: PropTypes.object,
    visible: PropTypes.looseBool,
    disabled: PropTypes.looseBool,
    dropdownClassName: PropTypes.string,
    dropdownStyle: PropTypes.object,
    placement: PropTypes.string,
    empty: PropTypes.looseBool,
    prefixCls: PropTypes.string,
    popupClassName: PropTypes.string,
    animation: PropTypes.string,
    transitionName: PropTypes.string,
    getPopupContainer: PropTypes.func,
    dropdownRender: PropTypes.func,
    containerWidth: PropTypes.number,
    dropdownMatchSelectWidth: PropTypes.oneOfType([Number, Boolean]).def(true),
    popupElement: PropTypes.any,
    direction: PropTypes.string,
    getTriggerDOMNode: PropTypes.func,
};
export default SelectTrigger;
