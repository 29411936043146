import Tabs, { TabPane } from './src';
Tabs.TabPane = Object.assign(Object.assign({}, TabPane), { name: 'ATabPane', __ANT_TAB_PANE: true });
/* istanbul ignore next */
Tabs.install = function (app) {
    app.component(Tabs.name, Tabs);
    app.component(Tabs.TabPane.name, Tabs.TabPane);
    return app;
};
export default Tabs;
export { TabPane };
