var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, ref } from 'vue';
import generatePicker from '../date-picker/generatePicker';
import { commonProps, datePickerProps, rangePickerProps, } from '../date-picker/generatePicker/props';
import devWarning from '../vc-util/devWarning';
import { useInjectFormItemContext } from '../form/FormItemContext';
const timePickerProps = {
    format: String,
    showNow: { type: Boolean, default: undefined },
    showHour: { type: Boolean, default: undefined },
    showMinute: { type: Boolean, default: undefined },
    showSecond: { type: Boolean, default: undefined },
    use12Hours: { type: Boolean, default: undefined },
    hourStep: Number,
    minuteStep: Number,
    secondStep: Number,
    hideDisabledOptions: { type: Boolean, default: undefined },
    popupClassName: String,
};
function createTimePicker(generateConfig) {
    const DatePicker = generatePicker(generateConfig, Object.assign(Object.assign({}, timePickerProps), { order: { type: Boolean, default: true } }));
    const { TimePicker: InternalTimePicker, RangePicker: InternalRangePicker } = DatePicker;
    const TimePicker = defineComponent({
        name: 'ATimePicker',
        inheritAttrs: false,
        slot: ['addon', 'renderExtraFooter', 'suffixIcon', 'clearIcon'],
        emits: ['change', 'openChange', 'focus', 'blur', 'ok', 'update:value', 'update:open'],
        setup(props, { slots, expose, emit, attrs }) {
            const formItemContext = useInjectFormItemContext();
            devWarning(!(slots.addon || props.addon), 'TimePicker', '`addon` is deprecated. Please use `v-slot:renderExtraFooter` instead.');
            const pickerRef = ref();
            expose({
                focus: () => {
                    var _a;
                    (_a = pickerRef.value) === null || _a === void 0 ? void 0 : _a.focus();
                },
                blur: () => {
                    var _a;
                    (_a = pickerRef.value) === null || _a === void 0 ? void 0 : _a.blur();
                },
            });
            const onChange = (value, dateString) => {
                emit('update:value', value);
                emit('change', value, dateString);
                formItemContext.onFieldChange();
            };
            const onOpenChange = (open) => {
                emit('update:open', open);
                emit('openChange', open);
            };
            const onFocus = () => {
                emit('focus');
            };
            const onBlur = () => {
                emit('blur');
                formItemContext.onFieldBlur();
            };
            const onOk = (value) => {
                emit('ok', value);
            };
            return () => {
                const { id = formItemContext.id.value } = props, restProps = __rest(props, ["id"]);
                return (<InternalTimePicker {...attrs} {...restProps} id={id} dropdownClassName={props.popupClassName} mode={undefined} ref={pickerRef} renderExtraFooter={props.addon || slots.addon || props.renderExtraFooter || slots.renderExtraFooter} onChange={onChange} onOpenChange={onOpenChange} onFocus={onFocus} onBlur={onBlur} onOk={onOk} v-slots={slots}/>);
            };
        },
    });
    TimePicker.props = Object.assign(Object.assign(Object.assign(Object.assign({}, commonProps()), datePickerProps()), timePickerProps), { addon: { type: Function } });
    const TimeRangePicker = defineComponent({
        name: 'ATimeRangePicker',
        inheritAttrs: false,
        slot: ['renderExtraFooter', 'suffixIcon', 'clearIcon'],
        emits: [
            'change',
            'panelChange',
            'ok',
            'openChange',
            'update:value',
            'update:open',
            'calendarChange',
            'focus',
            'blur',
        ],
        setup(props, { slots, expose, emit, attrs }) {
            const pickerRef = ref();
            const formItemContext = useInjectFormItemContext();
            expose({
                focus: () => {
                    var _a;
                    (_a = pickerRef.value) === null || _a === void 0 ? void 0 : _a.focus();
                },
                blur: () => {
                    var _a;
                    (_a = pickerRef.value) === null || _a === void 0 ? void 0 : _a.blur();
                },
            });
            const onChange = (values, dateStrings) => {
                emit('update:value', values);
                emit('change', values, dateStrings);
                formItemContext.onFieldChange();
            };
            const onOpenChange = (open) => {
                emit('update:open', open);
                emit('openChange', open);
            };
            const onFocus = () => {
                emit('focus');
            };
            const onBlur = () => {
                emit('blur');
                formItemContext.onFieldBlur();
            };
            const onPanelChange = (values, modes) => {
                emit('panelChange', values, modes);
            };
            const onOk = (values) => {
                emit('ok', values);
            };
            const onCalendarChange = (values, dateStrings, info) => {
                emit('calendarChange', values, dateStrings, info);
            };
            return () => {
                const { id = formItemContext.id.value } = props, restProps = __rest(props, ["id"]);
                return (<InternalRangePicker {...attrs} {...restProps} id={id} dropdownClassName={props.popupClassName} picker="time" mode={undefined} ref={pickerRef} onChange={onChange} onOpenChange={onOpenChange} onFocus={onFocus} onBlur={onBlur} onPanelChange={onPanelChange} onOk={onOk} onCalendarChange={onCalendarChange} v-slots={slots}/>);
            };
        },
    });
    TimeRangePicker.props = Object.assign(Object.assign(Object.assign(Object.assign({}, commonProps()), rangePickerProps()), timePickerProps), { order: { type: Boolean, default: true } });
    return {
        TimePicker,
        TimeRangePicker,
    };
}
export default createTimePicker;
