import { shallowRef, watchEffect } from 'vue';
/**
 * Return cached Key Value map with DataNode.
 * Only re-calculate when `flattenOptions` changed.
 */
export default function useKeyValueMap(flattenOptions) {
    const cacheKeyMap = shallowRef(new Map());
    const cacheValueMap = shallowRef(new Map());
    watchEffect(() => {
        const newCacheKeyMap = new Map();
        const newCacheValueMap = new Map();
        // Cache options by key
        flattenOptions.value.forEach((dataNode) => {
            newCacheKeyMap.set(dataNode.key, dataNode);
            newCacheValueMap.set(dataNode.data.value, dataNode);
        });
        cacheKeyMap.value = newCacheKeyMap;
        cacheValueMap.value = newCacheValueMap;
    });
    return [cacheKeyMap, cacheValueMap];
}
