import { getPropsSlot } from '../../_util/props-util';
import { computed, defineComponent } from 'vue';
import PropTypes from '../../_util/vue-types';
import { useInjectMenu } from './hooks/useMenuContext';
import { useMeasure } from './hooks/useKeyPath';
const menuItemGroupProps = {
    title: PropTypes.any,
};
export default defineComponent({
    name: 'AMenuItemGroup',
    inheritAttrs: false,
    props: menuItemGroupProps,
    slots: ['title'],
    setup(props, { slots, attrs }) {
        const { prefixCls } = useInjectMenu();
        const groupPrefixCls = computed(() => `${prefixCls.value}-item-group`);
        const isMeasure = useMeasure();
        return () => {
            var _a, _b;
            if (isMeasure)
                return (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots);
            return (<li {...attrs} onClick={e => e.stopPropagation()} class={groupPrefixCls.value}>
          <div title={typeof props.title === 'string' ? props.title : undefined} class={`${groupPrefixCls.value}-title`}>
            {getPropsSlot(slots, props, 'title')}
          </div>
          <ul class={`${groupPrefixCls.value}-list`}>{(_b = slots.default) === null || _b === void 0 ? void 0 : _b.call(slots)}</ul>
        </li>);
        };
    },
});
