import { createVNode, render as vueRender } from 'vue';
import ConfirmDialog from './ConfirmDialog';
import { destroyFns } from './Modal';
import ConfigProvider, { globalConfigForApi } from '../config-provider';
import omit from '../_util/omit';
const confirm = (config) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    let currentConfig = Object.assign(Object.assign({}, omit(config, ['parentContext', 'appContext'])), { close, visible: true });
    let confirmDialogInstance = null;
    function close(...args) {
        currentConfig = Object.assign(Object.assign({}, currentConfig), { visible: false, afterClose: destroy.bind(this, ...args) });
        update(currentConfig);
    }
    function update(newConfig) {
        currentConfig = Object.assign(Object.assign({}, currentConfig), newConfig);
        if (confirmDialogInstance) {
            Object.assign(confirmDialogInstance.component.props, currentConfig);
            confirmDialogInstance.component.update();
        }
    }
    function destroy(...args) {
        if (confirmDialogInstance && div.parentNode) {
            // destroy
            vueRender(null, div);
            confirmDialogInstance.component.update();
            confirmDialogInstance = null;
            div.parentNode.removeChild(div);
        }
        const triggerCancel = args.some(param => param && param.triggerCancel);
        if (config.onCancel && triggerCancel) {
            config.onCancel(...args);
        }
        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i];
            if (fn === close) {
                destroyFns.splice(i, 1);
                break;
            }
        }
    }
    const Wrapper = (p) => {
        const global = globalConfigForApi;
        const rootPrefixCls = global.prefixCls;
        const prefixCls = p.prefixCls || `${rootPrefixCls}-modal`;
        return (<ConfigProvider {...global} notUpdateGlobalConfig={true} prefixCls={rootPrefixCls}>
        <ConfirmDialog {...p} prefixCls={prefixCls}></ConfirmDialog>
      </ConfigProvider>);
    };
    function render(props) {
        const vm = createVNode(Wrapper, Object.assign({}, props));
        vm.appContext = config.parentContext || config.appContext || vm.appContext;
        vueRender(vm, div);
        return vm;
    }
    confirmDialogInstance = render(currentConfig);
    destroyFns.push(close);
    return {
        destroy: close,
        update,
    };
};
export default confirm;
