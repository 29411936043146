import { getRawValueLabeled } from '../utils/valueUtil';
import { formatStrategyKeys } from '../utils/strategyUtil';
import { shallowRef, watchEffect } from 'vue';
/** Return  */
export default function useSelectValues(rawValues, { value, getEntityByValue, getEntityByKey, treeConduction, showCheckedStrategy, conductKeyEntities, getLabelProp, }) {
    const rawValueLabeled = shallowRef([]);
    watchEffect(() => {
        let mergedRawValues = rawValues.value;
        if (treeConduction.value) {
            const rawKeys = formatStrategyKeys(rawValues.value.map(val => {
                const entity = getEntityByValue(val);
                return entity ? entity.key : val;
            }), showCheckedStrategy.value, conductKeyEntities.value);
            mergedRawValues = rawKeys.map(key => {
                const entity = getEntityByKey(key);
                return entity ? entity.data.value : key;
            });
        }
        rawValueLabeled.value = getRawValueLabeled(mergedRawValues, value.value, getEntityByValue, getLabelProp);
    });
    return rawValueLabeled;
}
