function commonProps() {
    return {
        id: String,
        dropdownClassName: String,
        dropdownAlign: { type: Object },
        popupStyle: { type: Object },
        transitionName: String,
        placeholder: String,
        allowClear: { type: Boolean, default: undefined },
        autofocus: { type: Boolean, default: undefined },
        disabled: { type: Boolean, default: undefined },
        tabindex: Number,
        open: { type: Boolean, default: undefined },
        defaultOpen: { type: Boolean, default: undefined },
        /** Make input readOnly to avoid popup keyboard in mobile */
        inputReadOnly: { type: Boolean, default: undefined },
        // Value
        // format:  string | CustomFormat<DateType> | (string | CustomFormat<DateType>)[];
        // Render
        // suffixIcon?: VueNode;
        // clearIcon?: VueNode;
        // prevIcon?: VueNode;
        // nextIcon?: VueNode;
        // superPrevIcon?: VueNode;
        // superNextIcon?: VueNode;
        getPopupContainer: { type: Function },
        panelRender: { type: Function },
        // // Events
        // onChange?: (value: DateType | null, dateString: string) => void;
        // onOpenChange?: (open: boolean) => void;
        // onFocus?: FocusEventHandler;
        // onBlur?: FocusEventHandler;
        // onMousedown?: MouseEventHandler;
        // onMouseup?: MouseEventHandler;
        // onMouseenter?: MouseEventHandler;
        // onMouseleave?: MouseEventHandler;
        // onClick?: MouseEventHandler;
        // onContextmenu?: MouseEventHandler;
        // onKeydown?: (event: KeyboardEvent, preventDefault: () => void) => void;
        // WAI-ARIA
        role: String,
        name: String,
        autocomplete: String,
        direction: { type: String },
        showToday: { type: Boolean, default: undefined },
        showTime: {
            type: [Boolean, Object],
            default: undefined,
        },
        locale: { type: Object },
        size: { type: String },
        bordered: { type: Boolean, default: undefined },
        dateRender: { type: Function },
        disabledDate: { type: Function },
        mode: { type: String },
        picker: { type: String },
        valueFormat: String,
    };
}
function datePickerProps() {
    return {
        defaultPickerValue: { type: [String, Object] },
        defaultValue: { type: [String, Object] },
        value: { type: [String, Object] },
        disabledTime: { type: Function },
        format: {
            type: [String, Function, Array],
        },
        renderExtraFooter: { type: Function },
        showNow: { type: Boolean, default: undefined },
        monthCellRender: { type: Function },
        // deprecated  Please use `monthCellRender"` instead.',
        monthCellContentRender: { type: Function },
    };
}
function rangePickerProps() {
    return {
        allowEmpty: { type: Array },
        dateRender: { type: Function },
        defaultPickerValue: {
            type: Array,
        },
        defaultValue: { type: Array },
        value: { type: Array },
        disabledTime: {
            type: Function,
        },
        disabled: { type: [Boolean, Array] },
        format: String,
        renderExtraFooter: { type: Function },
        separator: { type: String },
        ranges: {
            type: Object,
        },
        placeholder: Array,
        mode: { type: Array },
    };
}
export { commonProps, datePickerProps, rangePickerProps };
