import PropTypes from '../../_util/vue-types';
export const propTypes = {
    gapDegree: PropTypes.number,
    gapPosition: {
        type: String,
    },
    percent: {
        type: [Array, Number],
    },
    prefixCls: PropTypes.string,
    strokeColor: {
        type: [Object, String, Array],
    },
    strokeLinecap: {
        type: String,
    },
    strokeWidth: PropTypes.number,
    trailColor: PropTypes.string,
    trailWidth: PropTypes.number,
    transition: PropTypes.string,
};
