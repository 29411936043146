var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { computed, ref, defineComponent } from 'vue';
import classNames from '../_util/classNames';
import Input from './Input';
import SearchOutlined from '@ant-design/icons-vue/SearchOutlined';
import inputProps from './inputProps';
import Button from '../button';
import { cloneElement } from '../_util/vnode';
import PropTypes from '../_util/vue-types';
import isPlainObject from 'lodash-es/isPlainObject';
import useConfigInject from '../_util/hooks/useConfigInject';
import omit from '../_util/omit';
import isMobile from '../_util/isMobile';
export default defineComponent({
    name: 'AInputSearch',
    inheritAttrs: false,
    props: Object.assign(Object.assign({}, inputProps), { inputPrefixCls: PropTypes.string, 
        // 不能设置默认值 https://github.com/vueComponent/ant-design-vue/issues/1916
        enterButton: PropTypes.any, onSearch: {
            type: Function,
        } }),
    setup(props, { slots, attrs, expose, emit }) {
        const inputRef = ref();
        const focus = () => {
            var _a;
            (_a = inputRef.value) === null || _a === void 0 ? void 0 : _a.focus();
        };
        const blur = () => {
            var _a;
            (_a = inputRef.value) === null || _a === void 0 ? void 0 : _a.blur();
        };
        expose({
            focus,
            blur,
        });
        const onChange = (e) => {
            emit('update:value', e.target.value);
            if (e && e.target && e.type === 'click') {
                emit('search', e.target.value, e);
            }
            emit('change', e);
        };
        const onMousedown = e => {
            var _a;
            if (document.activeElement === ((_a = inputRef.value) === null || _a === void 0 ? void 0 : _a.input)) {
                e.preventDefault();
            }
        };
        const onSearch = (e) => {
            var _a;
            emit('search', (_a = inputRef.value) === null || _a === void 0 ? void 0 : _a.stateValue, e);
            if (!isMobile.tablet) {
                inputRef.value.focus();
            }
        };
        const { prefixCls, getPrefixCls, direction, size } = useConfigInject('input-search', props);
        const inputPrefixCls = computed(() => getPrefixCls('input', props.inputPrefixCls));
        return () => {
            var _a, _b, _c, _d;
            const { disabled, loading, addonAfter = (_a = slots.addonAfter) === null || _a === void 0 ? void 0 : _a.call(slots), suffix = (_b = slots.suffix) === null || _b === void 0 ? void 0 : _b.call(slots) } = props, restProps = __rest(props, ["disabled", "loading", "addonAfter", "suffix"]);
            let { enterButton = (_d = (_c = slots.enterButton) === null || _c === void 0 ? void 0 : _c.call(slots)) !== null && _d !== void 0 ? _d : false } = props;
            enterButton = enterButton || enterButton === '';
            const searchIcon = typeof enterButton === 'boolean' ? <SearchOutlined /> : null;
            const btnClassName = `${prefixCls.value}-button`;
            const enterButtonAsElement = Array.isArray(enterButton) ? enterButton[0] : enterButton;
            let button;
            const isAntdButton = enterButtonAsElement.type &&
                isPlainObject(enterButtonAsElement.type) &&
                enterButtonAsElement.type.__ANT_BUTTON;
            if (isAntdButton || enterButtonAsElement.tagName === 'button') {
                button = cloneElement(enterButtonAsElement, Object.assign({ onMousedown, onClick: onSearch, key: 'enterButton' }, (isAntdButton
                    ? {
                        class: btnClassName,
                        size: size.value,
                    }
                    : {})), false);
            }
            else {
                const iconOnly = searchIcon && !enterButton;
                button = (<Button class={btnClassName} type={enterButton ? 'primary' : undefined} size={size.value} disabled={disabled} key="enterButton" onMousedown={onMousedown} onClick={onSearch} loading={loading} icon={iconOnly ? searchIcon : null}>
            {iconOnly ? null : searchIcon || enterButton}
          </Button>);
            }
            if (addonAfter) {
                button = [button, addonAfter];
            }
            const cls = classNames(prefixCls.value, {
                [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
                [`${prefixCls.value}-${size.value}`]: !!size.value,
                [`${prefixCls.value}-with-button`]: !!enterButton,
            }, attrs.class);
            return (<Input ref={inputRef} {...omit(restProps, ['onUpdate:value', 'onSearch', 'enterButton'])} {...attrs} onPressEnter={onSearch} size={size.value} prefixCls={inputPrefixCls.value} addonAfter={button} suffix={suffix} onChange={onChange} class={cls} disabled={disabled} v-slots={slots}/>);
        };
    },
});
