import classNames from '../_util/classNames';
import { inject, defineComponent, ref, watch, onMounted, onBeforeUnmount, provide } from 'vue';
import PropTypes from '../_util/vue-types';
import { tuple } from '../_util/type';
import initDefaultProps from '../_util/props-util/initDefaultProps';
import isNumeric from '../_util/isNumeric';
import BarsOutlined from '@ant-design/icons-vue/BarsOutlined';
import RightOutlined from '@ant-design/icons-vue/RightOutlined';
import LeftOutlined from '@ant-design/icons-vue/LeftOutlined';
import useConfigInject from '../_util/hooks/useConfigInject';
import { SiderCollapsedKey, SiderHookProviderKey } from './injectionKey';
const dimensionMaxMap = {
    xs: '479.98px',
    sm: '575.98px',
    md: '767.98px',
    lg: '991.98px',
    xl: '1199.98px',
    xxl: '1599.98px',
    xxxl: '1999.98px',
};
export const siderProps = {
    prefixCls: PropTypes.string,
    collapsible: PropTypes.looseBool,
    collapsed: PropTypes.looseBool,
    defaultCollapsed: PropTypes.looseBool,
    reverseArrow: PropTypes.looseBool,
    zeroWidthTriggerStyle: PropTypes.style,
    trigger: PropTypes.any,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    collapsedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    breakpoint: PropTypes.oneOf(tuple('xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl')),
    theme: PropTypes.oneOf(tuple('light', 'dark')).def('dark'),
    onBreakpoint: Function,
    onCollapse: Function,
};
const generateId = (() => {
    let i = 0;
    return (prefix = '') => {
        i += 1;
        return `${prefix}${i}`;
    };
})();
export default defineComponent({
    name: 'ALayoutSider',
    inheritAttrs: false,
    props: initDefaultProps(siderProps, {
        collapsible: false,
        defaultCollapsed: false,
        reverseArrow: false,
        width: 200,
        collapsedWidth: 80,
    }),
    emits: ['breakpoint', 'update:collapsed', 'collapse'],
    setup(props, { emit, attrs, slots }) {
        const { prefixCls } = useConfigInject('layout-sider', props);
        const siderHook = inject(SiderHookProviderKey, undefined);
        const collapsed = ref(!!(props.collapsed !== undefined ? props.collapsed : props.defaultCollapsed));
        const below = ref(false);
        watch(() => props.collapsed, () => {
            collapsed.value = !!props.collapsed;
        });
        provide(SiderCollapsedKey, collapsed);
        const handleSetCollapsed = (value, type) => {
            if (props.collapsed === undefined) {
                collapsed.value = value;
            }
            emit('update:collapsed', value);
            emit('collapse', value, type);
        };
        // ========================= Responsive =========================
        const responsiveHandlerRef = ref((mql) => {
            below.value = mql.matches;
            emit('breakpoint', mql.matches);
            if (collapsed.value !== mql.matches) {
                handleSetCollapsed(mql.matches, 'responsive');
            }
        });
        let mql;
        function responsiveHandler(mql) {
            return responsiveHandlerRef.value(mql);
        }
        const uniqueId = generateId('ant-sider-');
        siderHook && siderHook.addSider(uniqueId);
        onMounted(() => {
            if (typeof window !== 'undefined') {
                const { matchMedia } = window;
                if (matchMedia && props.breakpoint && props.breakpoint in dimensionMaxMap) {
                    mql = matchMedia(`(max-width: ${dimensionMaxMap[props.breakpoint]})`);
                    try {
                        mql.addEventListener('change', responsiveHandler);
                    }
                    catch (error) {
                        mql.addListener(responsiveHandler);
                    }
                    responsiveHandler(mql);
                }
            }
        });
        onBeforeUnmount(() => {
            try {
                mql === null || mql === void 0 ? void 0 : mql.removeEventListener('change', responsiveHandler);
            }
            catch (error) {
                mql === null || mql === void 0 ? void 0 : mql.removeListener(responsiveHandler);
            }
            siderHook && siderHook.removeSider(uniqueId);
        });
        const toggle = () => {
            handleSetCollapsed(!collapsed.value, 'clickTrigger');
        };
        return () => {
            var _a;
            const pre = prefixCls.value;
            const { collapsedWidth, width, reverseArrow, zeroWidthTriggerStyle, trigger, collapsible, theme, } = props;
            const rawWidth = collapsed.value ? collapsedWidth : width;
            // use "px" as fallback unit for width
            const siderWidth = isNumeric(rawWidth) ? `${rawWidth}px` : String(rawWidth);
            // special trigger when collapsedWidth == 0
            const zeroWidthTrigger = parseFloat(String(collapsedWidth || 0)) === 0 ? (<span onClick={toggle} class={classNames(`${pre}-zero-width-trigger`, `${pre}-zero-width-trigger-${reverseArrow ? 'right' : 'left'}`)} style={zeroWidthTriggerStyle}>
            {trigger || <BarsOutlined />}
          </span>) : null;
            const iconObj = {
                expanded: reverseArrow ? <RightOutlined /> : <LeftOutlined />,
                collapsed: reverseArrow ? <LeftOutlined /> : <RightOutlined />,
            };
            const status = collapsed.value ? 'collapsed' : 'expanded';
            const defaultTrigger = iconObj[status];
            const triggerDom = trigger !== null
                ? zeroWidthTrigger || (<div class={`${pre}-trigger`} onClick={toggle} style={{ width: siderWidth }}>
                {trigger || defaultTrigger}
              </div>)
                : null;
            const divStyle = Object.assign(Object.assign({}, attrs.style), { flex: `0 0 ${siderWidth}`, maxWidth: siderWidth, minWidth: siderWidth, width: siderWidth });
            const siderCls = classNames(pre, `${pre}-${theme}`, {
                [`${pre}-collapsed`]: !!collapsed.value,
                [`${pre}-has-trigger`]: collapsible && trigger !== null && !zeroWidthTrigger,
                [`${pre}-below`]: !!below.value,
                [`${pre}-zero-width`]: parseFloat(siderWidth) === 0,
            }, attrs.class);
            return (<aside {...attrs} class={siderCls} style={divStyle}>
          <div class={`${pre}-children`}>{(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}</div>
          {collapsible || (below.value && zeroWidthTrigger) ? triggerDom : null}
        </aside>);
        };
    },
});
