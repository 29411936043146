import { computed, defineComponent, inject, provide } from 'vue';
const SelectContextKey = Symbol('SelectContextKey');
export const SelectContext = defineComponent({
    name: 'SelectContext',
    props: {
        value: { type: Object },
    },
    setup(props, { slots }) {
        provide(SelectContextKey, computed(() => props.value));
        return () => { var _a; return (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots); };
    },
});
export const useInjectTreeSelectContext = () => {
    return inject(SelectContextKey, computed(() => ({})));
};
