import { watchEffect, defineComponent, ref, watch, toRaw } from 'vue';
import PropTypes from '../_util/vue-types';
import { getPropsSlot } from '../_util/props-util';
import classNames from '../_util/classNames';
import List from './list';
import Operation from './operation';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import defaultLocale from '../locale-provider/default';
import { withInstall } from '../_util/type';
import useConfigInject from '../_util/hooks/useConfigInject';
import { useInjectFormItemContext } from '../form/FormItemContext';
export const transferProps = {
    id: String,
    prefixCls: String,
    dataSource: { type: Array, default: [] },
    disabled: { type: Boolean, default: undefined },
    targetKeys: { type: Array, default: undefined },
    selectedKeys: { type: Array, default: undefined },
    render: { type: Function },
    listStyle: {
        type: [Function, Object],
        default: () => ({}),
    },
    operationStyle: PropTypes.style,
    titles: { type: Array },
    operations: { type: Array },
    showSearch: { type: Boolean, default: false },
    filterOption: { type: Function },
    searchPlaceholder: String,
    notFoundContent: PropTypes.any,
    locale: { type: Object, default: () => ({}) },
    rowKey: { type: Function },
    showSelectAll: { type: Boolean, default: undefined },
    selectAllLabels: { type: Array },
    children: { type: Function },
    oneWay: { type: Boolean, default: undefined },
    pagination: { type: [Object, Boolean], default: undefined },
};
const Transfer = defineComponent({
    name: 'ATransfer',
    inheritAttrs: false,
    props: transferProps,
    slots: [
        'leftTitle',
        'rightTitle',
        'children',
        'render',
        'notFoundContent',
        'leftSelectAllLabel',
        'rightSelectAllLabel',
        'footer',
    ],
    emits: ['update:targetKeys', 'update:selectedKeys', 'change', 'search', 'scroll', 'selectChange'],
    setup(props, { emit, attrs, slots, expose }) {
        const { configProvider, prefixCls, direction } = useConfigInject('transfer', props);
        const sourceSelectedKeys = ref([]);
        const targetSelectedKeys = ref([]);
        const formItemContext = useInjectFormItemContext();
        watch(() => props.selectedKeys, () => {
            var _a, _b;
            sourceSelectedKeys.value =
                ((_a = props.selectedKeys) === null || _a === void 0 ? void 0 : _a.filter(key => props.targetKeys.indexOf(key) === -1)) || [];
            targetSelectedKeys.value =
                ((_b = props.selectedKeys) === null || _b === void 0 ? void 0 : _b.filter(key => props.targetKeys.indexOf(key) > -1)) || [];
        }, { immediate: true });
        const getLocale = (transferLocale, renderEmpty) => {
            // Keep old locale props still working.
            const oldLocale = {
                notFoundContent: renderEmpty('Transfer'),
            };
            const notFoundContent = getPropsSlot(slots, props, 'notFoundContent');
            if (notFoundContent) {
                oldLocale.notFoundContent = notFoundContent;
            }
            if (props.searchPlaceholder !== undefined) {
                oldLocale.searchPlaceholder = props.searchPlaceholder;
            }
            return Object.assign(Object.assign(Object.assign({}, transferLocale), oldLocale), props.locale);
        };
        const moveTo = (direction) => {
            const { targetKeys = [], dataSource = [] } = props;
            const moveKeys = direction === 'right' ? sourceSelectedKeys.value : targetSelectedKeys.value;
            // filter the disabled options
            const newMoveKeys = moveKeys.filter(key => !dataSource.some(data => !!(key === data.key && data.disabled)));
            // move items to target box
            const newTargetKeys = direction === 'right'
                ? newMoveKeys.concat(targetKeys)
                : targetKeys.filter(targetKey => newMoveKeys.indexOf(targetKey) === -1);
            // empty checked keys
            const oppositeDirection = direction === 'right' ? 'left' : 'right';
            direction === 'right' ? (sourceSelectedKeys.value = []) : (targetSelectedKeys.value = []);
            emit('update:targetKeys', newTargetKeys);
            handleSelectChange(oppositeDirection, []);
            emit('change', newTargetKeys, direction, newMoveKeys);
            formItemContext.onFieldChange();
        };
        const moveToLeft = () => {
            moveTo('left');
        };
        const moveToRight = () => {
            moveTo('right');
        };
        const onItemSelectAll = (direction, selectedKeys) => {
            handleSelectChange(direction, selectedKeys);
        };
        const onLeftItemSelectAll = (selectedKeys) => {
            return onItemSelectAll('left', selectedKeys);
        };
        const onRightItemSelectAll = (selectedKeys) => {
            return onItemSelectAll('right', selectedKeys);
        };
        const handleSelectChange = (direction, holder) => {
            if (direction === 'left') {
                if (!props.selectedKeys) {
                    sourceSelectedKeys.value = holder;
                }
                emit('update:selectedKeys', [...holder, ...targetSelectedKeys.value]);
                emit('selectChange', holder, toRaw(targetSelectedKeys.value));
            }
            else {
                if (!props.selectedKeys) {
                    targetSelectedKeys.value = holder;
                }
                emit('update:selectedKeys', [...holder, ...sourceSelectedKeys.value]);
                emit('selectChange', toRaw(sourceSelectedKeys.value), holder);
            }
        };
        const handleFilter = (direction, e) => {
            const value = e.target.value;
            emit('search', direction, value);
        };
        const handleLeftFilter = (e) => {
            handleFilter('left', e);
        };
        const handleRightFilter = (e) => {
            handleFilter('right', e);
        };
        const handleClear = (direction) => {
            emit('search', direction, '');
        };
        const handleLeftClear = () => {
            handleClear('left');
        };
        const handleRightClear = () => {
            handleClear('right');
        };
        const onItemSelect = (direction, selectedKey, checked) => {
            const holder = direction === 'left' ? [...sourceSelectedKeys.value] : [...targetSelectedKeys.value];
            const index = holder.indexOf(selectedKey);
            if (index > -1) {
                holder.splice(index, 1);
            }
            if (checked) {
                holder.push(selectedKey);
            }
            handleSelectChange(direction, holder);
        };
        const onLeftItemSelect = (selectedKey, checked) => {
            return onItemSelect('left', selectedKey, checked);
        };
        const onRightItemSelect = (selectedKey, checked) => {
            return onItemSelect('right', selectedKey, checked);
        };
        const onRightItemRemove = (targetedKeys) => {
            const { targetKeys = [] } = props;
            const newTargetKeys = targetKeys.filter(key => !targetedKeys.includes(key));
            emit('update:targetKeys', newTargetKeys);
            emit('change', newTargetKeys, 'left', [...targetedKeys]);
        };
        const handleScroll = (direction, e) => {
            emit('scroll', direction, e);
        };
        const handleLeftScroll = (e) => {
            handleScroll('left', e);
        };
        const handleRightScroll = (e) => {
            handleScroll('right', e);
        };
        const handleListStyle = (listStyle, direction) => {
            if (typeof listStyle === 'function') {
                return listStyle({ direction });
            }
            return listStyle;
        };
        const leftDataSource = ref([]);
        const rightDataSource = ref([]);
        watchEffect(() => {
            const { dataSource, rowKey, targetKeys = [] } = props;
            const ld = [];
            const rd = new Array(targetKeys.length);
            dataSource.forEach(record => {
                if (rowKey) {
                    record.key = rowKey(record);
                }
                // rightDataSource should be ordered by targetKeys
                // leftDataSource should be ordered by dataSource
                const indexOfKey = targetKeys.indexOf(record.key);
                if (indexOfKey !== -1) {
                    rd[indexOfKey] = record;
                }
                else {
                    ld.push(record);
                }
            });
            leftDataSource.value = ld;
            rightDataSource.value = rd;
        });
        expose({ handleSelectChange });
        const renderTransfer = (transferLocale) => {
            var _a, _b, _c, _d, _e, _f;
            const { disabled, operations = [], showSearch, listStyle, operationStyle, filterOption, showSelectAll, selectAllLabels = [], oneWay, pagination, id = formItemContext.id.value, } = props;
            const { class: className, style } = attrs;
            const children = slots.children;
            const mergedPagination = !children && pagination;
            const renderEmpty = configProvider.renderEmpty;
            const locale = getLocale(transferLocale, renderEmpty);
            const { footer } = slots;
            const renderItem = props.render || slots.render;
            const leftActive = targetSelectedKeys.value.length > 0;
            const rightActive = sourceSelectedKeys.value.length > 0;
            const cls = classNames(prefixCls.value, className, {
                [`${prefixCls.value}-disabled`]: disabled,
                [`${prefixCls.value}-customize-list`]: !!children,
            });
            const titles = props.titles;
            const leftTitle = (_c = (_a = (titles && titles[0])) !== null && _a !== void 0 ? _a : (_b = slots.leftTitle) === null || _b === void 0 ? void 0 : _b.call(slots)) !== null && _c !== void 0 ? _c : (locale.titles || ['', ''])[0];
            const rightTitle = (_f = (_d = (titles && titles[1])) !== null && _d !== void 0 ? _d : (_e = slots.rightTitle) === null || _e === void 0 ? void 0 : _e.call(slots)) !== null && _f !== void 0 ? _f : (locale.titles || ['', ''])[1];
            return (<div class={cls} style={style} id={id}>
          <List key="leftList" prefixCls={`${prefixCls.value}-list`} dataSource={leftDataSource.value} filterOption={filterOption} style={handleListStyle(listStyle, 'left')} checkedKeys={sourceSelectedKeys.value} handleFilter={handleLeftFilter} handleClear={handleLeftClear} onItemSelect={onLeftItemSelect} onItemSelectAll={onLeftItemSelectAll} renderItem={renderItem} showSearch={showSearch} renderList={children} onScroll={handleLeftScroll} disabled={disabled} direction="left" showSelectAll={showSelectAll} selectAllLabel={selectAllLabels[0] || slots.leftSelectAllLabel} pagination={mergedPagination} {...locale} v-slots={{ titleText: () => leftTitle, footer }}/>
          <Operation key="operation" class={`${prefixCls.value}-operation`} rightActive={rightActive} rightArrowText={operations[0]} moveToRight={moveToRight} leftActive={leftActive} leftArrowText={operations[1]} moveToLeft={moveToLeft} style={operationStyle} disabled={disabled} direction={direction.value} oneWay={oneWay}/>
          <List key="rightList" prefixCls={`${prefixCls.value}-list`} dataSource={rightDataSource.value} filterOption={filterOption} style={handleListStyle(listStyle, 'right')} checkedKeys={targetSelectedKeys.value} handleFilter={handleRightFilter} handleClear={handleRightClear} onItemSelect={onRightItemSelect} onItemSelectAll={onRightItemSelectAll} onItemRemove={onRightItemRemove} renderItem={renderItem} showSearch={showSearch} renderList={children} onScroll={handleRightScroll} disabled={disabled} direction="right" showSelectAll={showSelectAll} selectAllLabel={selectAllLabels[1] || slots.rightSelectAllLabel} showRemove={oneWay} pagination={mergedPagination} {...locale} v-slots={{ titleText: () => rightTitle, footer }}/>
        </div>);
        };
        return () => (<LocaleReceiver componentName="Transfer" defaultLocale={defaultLocale.Transfer} children={renderTransfer}/>);
    },
});
export default withInstall(Transfer);
