import { useInjectTreeSelectContext } from './Context';
import { computed, defineComponent, nextTick, ref, shallowRef, watch } from 'vue';
import { optionListProps } from './props';
import useMemo from '../_util/hooks/useMemo';
import KeyCode from '../_util/KeyCode';
import Tree from '../vc-tree/Tree';
const HIDDEN_STYLE = {
    width: 0,
    height: 0,
    display: 'flex',
    overflow: 'hidden',
    opacity: 0,
    border: 0,
    padding: 0,
    margin: 0,
};
export default defineComponent({
    name: 'OptionList',
    inheritAttrs: false,
    props: optionListProps(),
    slots: ['notFoundContent', 'menuItemSelectedIcon'],
    setup(props, { slots, expose }) {
        const context = useInjectTreeSelectContext();
        const treeRef = ref();
        const memoOptions = useMemo(() => props.options, [() => props.open, () => props.options], next => next[0]);
        const valueKeys = computed(() => {
            const { checkedKeys, getEntityByValue } = context.value;
            return checkedKeys.map(val => {
                const entity = getEntityByValue(val);
                return entity ? entity.key : null;
            });
        });
        const mergedCheckedKeys = computed(() => {
            const { checkable, halfCheckedKeys } = context.value;
            if (!checkable) {
                return null;
            }
            return {
                checked: valueKeys.value,
                halfChecked: halfCheckedKeys,
            };
        });
        watch(() => props.open, () => {
            nextTick(() => {
                var _a;
                if (props.open && !props.multiple && valueKeys.value.length) {
                    (_a = treeRef.value) === null || _a === void 0 ? void 0 : _a.scrollTo({ key: valueKeys.value[0] });
                }
            });
        }, { immediate: true, flush: 'post' });
        // ========================== Search ==========================
        const lowerSearchValue = computed(() => String(props.searchValue).toLowerCase());
        const filterTreeNode = (treeNode) => {
            if (!lowerSearchValue.value) {
                return false;
            }
            return String(treeNode[context.value.treeNodeFilterProp])
                .toLowerCase()
                .includes(lowerSearchValue.value);
        };
        // =========================== Keys ===========================
        const expandedKeys = shallowRef(context.value.treeDefaultExpandedKeys);
        const searchExpandedKeys = shallowRef(null);
        watch(() => props.searchValue, () => {
            if (props.searchValue) {
                searchExpandedKeys.value = props.flattenOptions.map(o => o.key);
            }
        }, {
            immediate: true,
        });
        const mergedExpandedKeys = computed(() => {
            if (context.value.treeExpandedKeys) {
                return [...context.value.treeExpandedKeys];
            }
            return props.searchValue ? searchExpandedKeys.value : expandedKeys.value;
        });
        const onInternalExpand = (keys) => {
            var _a, _b;
            expandedKeys.value = keys;
            searchExpandedKeys.value = keys;
            (_b = (_a = context.value).onTreeExpand) === null || _b === void 0 ? void 0 : _b.call(_a, keys);
        };
        // ========================== Events ==========================
        const onListMouseDown = (event) => {
            event.preventDefault();
        };
        const onInternalSelect = (_, { node: { key } }) => {
            var _a, _b;
            const { getEntityByKey, checkable, checkedKeys } = context.value;
            const entity = getEntityByKey(key, checkable ? 'checkbox' : 'select');
            if (entity !== null) {
                (_a = props.onSelect) === null || _a === void 0 ? void 0 : _a.call(props, entity.data.value, {
                    selected: !checkedKeys.includes(entity.data.value),
                });
            }
            if (!props.multiple) {
                (_b = props.onToggleOpen) === null || _b === void 0 ? void 0 : _b.call(props, false);
            }
        };
        // ========================= Keyboard =========================
        const activeKey = ref(null);
        const activeEntity = computed(() => context.value.getEntityByKey(activeKey.value));
        const setActiveKey = (key) => {
            activeKey.value = key;
        };
        expose({
            scrollTo: (...args) => { var _a, _b; return (_b = (_a = treeRef.value) === null || _a === void 0 ? void 0 : _a.scrollTo) === null || _b === void 0 ? void 0 : _b.call(_a, ...args); },
            onKeydown: (event) => {
                var _a, _b;
                const { which } = event;
                switch (which) {
                    // >>> Arrow keys
                    case KeyCode.UP:
                    case KeyCode.DOWN:
                    case KeyCode.LEFT:
                    case KeyCode.RIGHT:
                        (_a = treeRef.value) === null || _a === void 0 ? void 0 : _a.onKeydown(event);
                        break;
                    // >>> Select item
                    case KeyCode.ENTER: {
                        const { selectable, value } = ((_b = activeEntity.value) === null || _b === void 0 ? void 0 : _b.data.node) || {};
                        if (selectable !== false) {
                            onInternalSelect(null, {
                                node: { key: activeKey.value },
                                selected: !context.value.checkedKeys.includes(value),
                            });
                        }
                        break;
                    }
                    // >>> Close
                    case KeyCode.ESC: {
                        props.onToggleOpen(false);
                    }
                }
            },
            onKeyup: () => { },
        });
        return () => {
            var _a;
            const { prefixCls, height, itemHeight, virtual, multiple, searchValue, open, notFoundContent = (_a = slots.notFoundContent) === null || _a === void 0 ? void 0 : _a.call(slots), onMouseenter, } = props;
            const { checkable, treeDefaultExpandAll, treeIcon, showTreeIcon, switcherIcon, treeLine, loadData, treeLoadedKeys, treeMotion, onTreeLoad, } = context.value;
            // ========================== Render ==========================
            if (memoOptions.value.length === 0) {
                return (<div role="listbox" class={`${prefixCls}-empty`} onMousedown={onListMouseDown}>
            {notFoundContent}
          </div>);
            }
            const treeProps = {};
            if (treeLoadedKeys) {
                treeProps.loadedKeys = treeLoadedKeys;
            }
            if (mergedExpandedKeys.value) {
                treeProps.expandedKeys = mergedExpandedKeys.value;
            }
            return (<div onMousedown={onListMouseDown} onMouseenter={onMouseenter}>
          {activeEntity.value && open && (<span style={HIDDEN_STYLE} aria-live="assertive">
              {activeEntity.value.data.value}
            </span>)}

          <Tree ref={treeRef} focusable={false} prefixCls={`${prefixCls}-tree`} treeData={memoOptions.value} height={height} itemHeight={itemHeight} virtual={virtual} multiple={multiple} icon={treeIcon} showIcon={showTreeIcon} switcherIcon={switcherIcon} showLine={treeLine} loadData={searchValue ? null : loadData} motion={treeMotion} 
            // We handle keys by out instead tree self
            checkable={checkable} checkStrictly checkedKeys={mergedCheckedKeys.value} selectedKeys={!checkable ? valueKeys.value : []} defaultExpandAll={treeDefaultExpandAll} {...treeProps} 
            // Proxy event out
            onActiveChange={setActiveKey} onSelect={onInternalSelect} onCheck={onInternalSelect} onExpand={onInternalExpand} onLoad={onTreeLoad} filterTreeNode={filterTreeNode} v-slots={Object.assign(Object.assign({}, slots), { checkable: context.value.customCheckable })}/>
        </div>);
        };
    },
});
