import Upload from './Upload';
import Dragger from './Dragger';
Upload.Dragger = Dragger;
/* istanbul ignore next */
Upload.install = function (app) {
    app.component(Upload.name, Upload);
    app.component(Dragger.name, Dragger);
    return app;
};
export const UploadDragger = Dragger;
export default Upload;
