import PropTypes from '../_util/vue-types';
import { tuple } from '../_util/type';
export const progressStatuses = tuple('normal', 'exception', 'active', 'success');
const ProgressType = tuple('line', 'circle', 'dashboard');
const ProgressSize = tuple('default', 'small');
export const progressProps = () => ({
    prefixCls: PropTypes.string,
    type: PropTypes.oneOf(ProgressType),
    percent: PropTypes.number,
    format: { type: Function },
    status: PropTypes.oneOf(progressStatuses),
    showInfo: PropTypes.looseBool,
    strokeWidth: PropTypes.number,
    strokeLinecap: PropTypes.oneOf(tuple('butt', 'round', 'square')),
    strokeColor: {
        type: [String, Object],
    },
    trailColor: PropTypes.string,
    width: PropTypes.number,
    success: {
        type: Object,
        default: () => ({}),
    },
    gapDegree: PropTypes.number,
    gapPosition: PropTypes.oneOf(tuple('top', 'bottom', 'left', 'right')),
    size: PropTypes.oneOf(ProgressSize),
    steps: PropTypes.number,
    /** @deprecated Use `success` instead */
    successPercent: PropTypes.number,
});
