var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { watch, ref, onMounted, defineComponent, nextTick } from 'vue';
import classNames from '../_util/classNames';
import PropTypes from '../_util/vue-types';
import VcMentions, { Option } from '../vc-mentions';
import { mentionsProps as baseMentionsProps } from '../vc-mentions/src/mentionsProps';
import useConfigInject from '../_util/hooks/useConfigInject';
import { flattenChildren, getOptionProps } from '../_util/props-util';
import { useInjectFormItemContext } from '../form/FormItemContext';
import omit from '../_util/omit';
import { optionProps } from '../vc-mentions/src/Option';
const getMentions = (value = '', config) => {
    const { prefix = '@', split = ' ' } = config || {};
    const prefixList = Array.isArray(prefix) ? prefix : [prefix];
    return value
        .split(split)
        .map((str = '') => {
        let hitPrefix = null;
        prefixList.some(prefixStr => {
            const startStr = str.slice(0, prefixStr.length);
            if (startStr === prefixStr) {
                hitPrefix = prefixStr;
                return true;
            }
            return false;
        });
        if (hitPrefix !== null) {
            return {
                prefix: hitPrefix,
                value: str.slice(hitPrefix.length),
            };
        }
        return null;
    })
        .filter((entity) => !!entity && !!entity.value);
};
const mentionsProps = Object.assign(Object.assign({}, baseMentionsProps), { loading: PropTypes.looseBool, onFocus: {
        type: Function,
    }, onBlur: {
        type: Function,
    }, onSelect: {
        type: Function,
    }, onChange: {
        type: Function,
    }, notFoundContent: PropTypes.any, defaultValue: String, id: String });
const Mentions = defineComponent({
    name: 'AMentions',
    inheritAttrs: false,
    props: mentionsProps,
    emits: ['update:value', 'change', 'focus', 'blur', 'select', 'pressenter'],
    slots: ['notFoundContent', 'option'],
    setup(props, { slots, emit, attrs, expose }) {
        var _a, _b;
        const { prefixCls, renderEmpty, direction } = useConfigInject('mentions', props);
        const focused = ref(false);
        const vcMentions = ref(null);
        const value = ref((_b = (_a = props.value) !== null && _a !== void 0 ? _a : props.defaultValue) !== null && _b !== void 0 ? _b : '');
        const formItemContext = useInjectFormItemContext();
        watch(() => props.value, val => {
            value.value = val;
        });
        const handleFocus = (e) => {
            focused.value = true;
            emit('focus', e);
        };
        const handleBlur = (e) => {
            focused.value = false;
            emit('blur', e);
            formItemContext.onFieldBlur();
        };
        const handleSelect = (...args) => {
            emit('select', ...args);
            focused.value = true;
        };
        const handleChange = (val) => {
            if (props.value === undefined) {
                value.value = val;
            }
            emit('update:value', val);
            emit('change', val);
            formItemContext.onFieldChange();
        };
        const getNotFoundContent = () => {
            const notFoundContent = props.notFoundContent;
            if (notFoundContent !== undefined) {
                return notFoundContent;
            }
            if (slots.notFoundContent) {
                return slots.notFoundContent();
            }
            return renderEmpty.value('Select');
        };
        const getOptions = () => {
            var _a;
            return flattenChildren(((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)) || []).map(item => {
                var _a, _b;
                return Object.assign(Object.assign({}, getOptionProps(item)), { label: (_b = (_a = item.children) === null || _a === void 0 ? void 0 : _a.default) === null || _b === void 0 ? void 0 : _b.call(_a) });
            });
        };
        const focus = () => {
            vcMentions.value.focus();
        };
        const blur = () => {
            vcMentions.value.blur();
        };
        expose({ focus, blur });
        onMounted(() => {
            nextTick(() => {
                if (process.env.NODE_ENV === 'test') {
                    if (props.autofocus) {
                        focus();
                    }
                }
            });
        });
        return () => {
            const { disabled, getPopupContainer, rows = 1, id = formItemContext.id.value } = props, restProps = __rest(props, ["disabled", "getPopupContainer", "rows", "id"]);
            const { class: className } = attrs, otherAttrs = __rest(attrs, ["class"]);
            const otherProps = omit(restProps, ['defaultValue', 'onUpdate:value', 'prefixCls']);
            const mergedClassName = classNames(className, {
                [`${prefixCls.value}-disabled`]: disabled,
                [`${prefixCls.value}-focused`]: focused.value,
                [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
            });
            const mentionsProps = Object.assign(Object.assign(Object.assign(Object.assign({ prefixCls: prefixCls.value }, otherProps), { disabled, direction: direction.value, filterOption: props.filterOption, getPopupContainer, options: props.options || getOptions(), class: mergedClassName }), otherAttrs), { rows, onChange: handleChange, onSelect: handleSelect, onFocus: handleFocus, onBlur: handleBlur, ref: vcMentions, value: value.value, id });
            return (<VcMentions {...mentionsProps} v-slots={{ notFoundContent: getNotFoundContent, option: slots.option }}></VcMentions>);
        };
    },
});
/* istanbul ignore next */
export const MentionsOption = defineComponent(Object.assign(Object.assign({}, Option), { name: 'AMentionsOption', props: optionProps }));
export default Object.assign(Mentions, {
    Option: MentionsOption,
    getMentions,
    install: (app) => {
        app.component(Mentions.name, Mentions);
        app.component(MentionsOption.name, MentionsOption);
        return app;
    },
});
