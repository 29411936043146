import Cell from '../Cell';
import { defineComponent } from 'vue';
import { useInjectTable } from '../context/TableContext';
export default defineComponent({
    name: 'ExpandedRow',
    inheritAttrs: false,
    props: [
        'prefixCls',
        'component',
        'cellComponent',
        'fixHeader',
        'fixColumn',
        'horizonScroll',
        'componentWidth',
        'expanded',
        'colSpan',
    ],
    setup(props, { slots, attrs }) {
        const tableContext = useInjectTable();
        return () => {
            const { prefixCls, component: Component, cellComponent, fixHeader, fixColumn, expanded, componentWidth, colSpan, } = props;
            return (<Component class={attrs.class} style={{
                    display: expanded ? null : 'none',
                }}>
          <Cell component={cellComponent} prefixCls={prefixCls} colSpan={colSpan} v-slots={{
                    default: () => {
                        var _a;
                        let contentNode = (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots);
                        if (fixColumn) {
                            contentNode = (<div style={{
                                    width: `${componentWidth - (fixHeader ? tableContext.scrollbarSize : 0)}px`,
                                    position: 'sticky',
                                    left: 0,
                                    overflow: 'hidden',
                                }} class={`${prefixCls}-expanded-row-fixed`}>
                      {contentNode}
                    </div>);
                        }
                        return contentNode;
                    },
                }}></Cell>
        </Component>);
        };
    },
});
