import { computed } from 'vue';
export default function useCacheOptions(options) {
    const optionMap = computed(() => {
        const map = new Map();
        options.value.forEach(item => {
            const { value } = item;
            map.set(value, item);
        });
        return map;
    });
    const getValueOption = (valueList) => valueList.map(value => optionMap.value.get(value)).filter(Boolean);
    return getValueOption;
}
