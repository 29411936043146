import getRequestAnimationFrame, { cancelRequestAnimationFrame } from './getRequestAnimationFrame';
const oriRaf = getRequestAnimationFrame();
// Support call raf with delay specified frame
export default function raf(callback, delayFrames = 1) {
    let restFrames = delayFrames;
    function internalCallback() {
        restFrames -= 1;
        if (restFrames <= 0) {
            callback();
        }
        else {
            frame.id = oriRaf(internalCallback);
        }
    }
    const frame = {
        id: oriRaf(internalCallback),
    };
    return frame;
}
raf.cancel = function cancel(frame) {
    if (!frame)
        return;
    cancelRequestAnimationFrame(frame.id);
};
