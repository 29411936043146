import omit from '../_util/omit';
import warning from '../_util/warning';
import Base, { baseProps } from './Base';
const Text = (props, { slots, attrs }) => {
    const { ellipsis } = props;
    warning(typeof ellipsis !== 'object' ||
        !ellipsis ||
        (!('expandable' in ellipsis) && !('rows' in ellipsis)), 'Typography.Text', '`ellipsis` do not support `expandable` or `rows` props.');
    const textProps = Object.assign(Object.assign(Object.assign({}, props), { ellipsis: ellipsis && typeof ellipsis === 'object'
            ? omit(ellipsis, ['expandable', 'rows'])
            : ellipsis, component: 'span' }), attrs);
    return <Base {...textProps} v-slots={slots}></Base>;
};
Text.displayName = 'ATypographyText';
Text.inheritAttrs = false;
Text.props = omit(baseProps(), ['component']);
export default Text;
