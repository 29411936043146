import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, ref, watch, watchEffect, } from 'vue';
import ClearableLabeledInput from './ClearableLabeledInput';
import ResizableTextArea from './ResizableTextArea';
import { textAreaProps } from './inputProps';
import { fixControlledValue, resolveOnChange, triggerFocus } from './Input';
import classNames from '../_util/classNames';
import { useInjectFormItemContext } from '../form/FormItemContext';
import useConfigInject from '../_util/hooks/useConfigInject';
import omit from '../_util/omit';
function fixEmojiLength(value, maxLength) {
    return [...(value || '')].slice(0, maxLength).join('');
}
export default defineComponent({
    name: 'ATextarea',
    inheritAttrs: false,
    props: textAreaProps,
    setup(props, { attrs, expose, emit }) {
        const formItemContext = useInjectFormItemContext();
        const stateValue = ref(props.value === undefined ? props.defaultValue : props.value);
        const resizableTextArea = ref();
        const mergedValue = ref('');
        const { prefixCls, size, direction } = useConfigInject('input', props);
        const showCount = computed(() => {
            return props.showCount === '' || props.showCount || false;
        });
        // Max length value
        const hasMaxLength = computed(() => Number(props.maxlength) > 0);
        const compositing = ref(false);
        const instance = getCurrentInstance();
        watch(() => props.value, () => {
            var _a;
            if ('value' in instance.vnode.props || {}) {
                stateValue.value = (_a = props.value) !== null && _a !== void 0 ? _a : '';
            }
        });
        const focus = (option) => {
            var _a;
            triggerFocus((_a = resizableTextArea.value) === null || _a === void 0 ? void 0 : _a.textArea, option);
        };
        const blur = () => {
            var _a, _b;
            (_b = (_a = resizableTextArea.value) === null || _a === void 0 ? void 0 : _a.textArea) === null || _b === void 0 ? void 0 : _b.blur();
        };
        const setValue = (value, callback) => {
            if (stateValue.value === value) {
                return;
            }
            if (props.value === undefined) {
                stateValue.value = value;
            }
            else {
                nextTick(() => {
                    var _a, _b, _c;
                    if (resizableTextArea.value.textArea.value !== mergedValue.value) {
                        (_c = (_a = resizableTextArea.value) === null || _a === void 0 ? void 0 : (_b = _a.instance).update) === null || _c === void 0 ? void 0 : _c.call(_b);
                    }
                });
            }
            nextTick(() => {
                callback && callback();
            });
        };
        const handleKeyDown = (e) => {
            if (e.keyCode === 13) {
                emit('pressEnter', e);
            }
            emit('keydown', e);
        };
        const onBlur = e => {
            const { onBlur } = props;
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
            formItemContext.onFieldBlur();
        };
        const triggerChange = (e) => {
            emit('update:value', e.target.value);
            emit('change', e);
            emit('input', e);
            formItemContext.onFieldChange();
        };
        const handleReset = (e) => {
            resolveOnChange(resizableTextArea.value.textArea, e, triggerChange);
            setValue('', () => {
                focus();
            });
        };
        const handleChange = (e) => {
            const { value, composing } = e.target;
            compositing.value = e.isComposing || composing;
            if ((compositing.value && props.lazy) || stateValue.value === value)
                return;
            let triggerValue = e.currentTarget.value;
            if (hasMaxLength.value) {
                triggerValue = fixEmojiLength(triggerValue, props.maxlength);
            }
            resolveOnChange(e.currentTarget, e, triggerChange, triggerValue);
            setValue(triggerValue);
        };
        const renderTextArea = () => {
            var _a, _b;
            const { style, class: customClass } = attrs;
            const { bordered = true } = props;
            const resizeProps = Object.assign(Object.assign(Object.assign({}, omit(props, ['allowClear'])), attrs), { style: showCount.value ? {} : style, class: {
                    [`${prefixCls.value}-borderless`]: !bordered,
                    [`${customClass}`]: customClass && !showCount.value,
                    [`${prefixCls.value}-sm`]: size.value === 'small',
                    [`${prefixCls.value}-lg`]: size.value === 'large',
                }, showCount: null, prefixCls: prefixCls.value, onInput: handleChange, onChange: handleChange, onBlur, onKeydown: handleKeyDown });
            if ((_a = props.valueModifiers) === null || _a === void 0 ? void 0 : _a.lazy) {
                delete resizeProps.onInput;
            }
            return (<ResizableTextArea {...resizeProps} id={(_b = resizeProps.id) !== null && _b !== void 0 ? _b : formItemContext.id.value} ref={resizableTextArea} maxlength={props.maxlength}/>);
        };
        onMounted(() => {
            if (process.env.NODE_ENV === 'test') {
                if (props.autofocus) {
                    focus();
                }
            }
        });
        expose({
            focus,
            blur,
            resizableTextArea,
        });
        watchEffect(() => {
            let val = fixControlledValue(stateValue.value);
            if (!compositing.value &&
                hasMaxLength.value &&
                (props.value === null || props.value === undefined)) {
                // fix #27612 将value转为数组进行截取，解决 '😂'.length === 2 等emoji表情导致的截取乱码的问题
                val = fixEmojiLength(val, props.maxlength);
            }
            mergedValue.value = val;
        });
        return () => {
            const { maxlength, bordered = true } = props;
            const { style, class: customClass } = attrs;
            const inputProps = Object.assign(Object.assign(Object.assign({}, props), attrs), { prefixCls: prefixCls.value, inputType: 'text', handleReset, direction: direction.value, bordered, style: showCount.value ? undefined : style });
            let textareaNode = (<ClearableLabeledInput {...inputProps} value={mergedValue.value} v-slots={{ element: renderTextArea }}/>);
            if (showCount.value) {
                const valueLength = [...mergedValue.value].length;
                let dataCount = '';
                if (typeof showCount.value === 'object') {
                    dataCount = showCount.value.formatter({ count: valueLength, maxlength });
                }
                else {
                    dataCount = `${valueLength}${hasMaxLength.value ? ` / ${maxlength}` : ''}`;
                }
                textareaNode = (<div class={classNames(`${prefixCls.value}-textarea`, {
                        [`${prefixCls.value}-textarea-rtl`]: direction.value === 'rtl',
                    }, `${prefixCls.value}-textarea-show-count`, customClass)} style={style} data-count={dataCount}>
            {textareaNode}
          </div>);
            }
            return textareaNode;
        };
    },
});
