import { defineComponent } from 'vue';
import PropTypes from '../../_util/vue-types';
const tooltipContentProps = {
    prefixCls: PropTypes.string,
    id: PropTypes.string,
    overlayInnerStyle: PropTypes.any,
};
export default defineComponent({
    name: 'Content',
    props: tooltipContentProps,
    slots: ['overlay'],
    setup(props, { slots }) {
        return () => {
            var _a;
            return (<div class={`${props.prefixCls}-inner`} id={props.id} role="tooltip" style={props.overlayInnerStyle}>
        {(_a = slots.overlay) === null || _a === void 0 ? void 0 : _a.call(slots)}
      </div>);
        };
    },
});
