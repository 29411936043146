import { defineComponent, inject } from 'vue';
import Radio, { radioProps } from './Radio';
import useConfigInject from '../_util/hooks/useConfigInject';
export default defineComponent({
    name: 'ARadioButton',
    props: radioProps,
    setup(props, { slots }) {
        const { prefixCls } = useConfigInject('radio-button', props);
        const radioGroupContext = inject('radioGroupContext', undefined);
        return () => {
            var _a;
            const rProps = Object.assign(Object.assign({}, props), { prefixCls: prefixCls.value });
            if (radioGroupContext) {
                rProps.onChange = radioGroupContext.onRadioChange;
                rProps.checked = rProps.value === radioGroupContext.stateValue.value;
                rProps.disabled = rProps.disabled || radioGroupContext.props.disabled;
            }
            return <Radio {...rProps}>{(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}</Radio>;
        };
    },
});
