var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { computed, defineComponent, ref, watch } from 'vue';
import PropTypes from '../_util/vue-types';
import Trigger from '../vc-trigger';
import placements from './placements';
import { cloneElement } from '../_util/vnode';
import classNames from '../_util/classNames';
export default defineComponent({
    props: {
        minOverlayWidthMatchTrigger: PropTypes.looseBool,
        arrow: PropTypes.looseBool.def(false),
        prefixCls: PropTypes.string.def('rc-dropdown'),
        transitionName: PropTypes.string,
        overlayClassName: PropTypes.string.def(''),
        openClassName: PropTypes.string,
        animation: PropTypes.any,
        align: PropTypes.object,
        overlayStyle: PropTypes.style,
        placement: PropTypes.string.def('bottomLeft'),
        overlay: PropTypes.any,
        trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).def('hover'),
        alignPoint: PropTypes.looseBool,
        showAction: PropTypes.array,
        hideAction: PropTypes.array,
        getPopupContainer: PropTypes.func,
        visible: PropTypes.looseBool,
        defaultVisible: PropTypes.looseBool.def(false),
        mouseEnterDelay: PropTypes.number.def(0.15),
        mouseLeaveDelay: PropTypes.number.def(0.1),
    },
    emits: ['visibleChange', 'overlayClick'],
    slots: ['overlay'],
    setup(props, { slots, emit, expose }) {
        const triggerVisible = ref(!!props.visible);
        watch(() => props.visible, val => {
            if (val !== undefined) {
                triggerVisible.value = val;
            }
        });
        const triggerRef = ref();
        expose({
            triggerRef,
        });
        const onClick = (e) => {
            if (props.visible === undefined) {
                triggerVisible.value = false;
            }
            emit('overlayClick', e);
        };
        const onVisibleChange = (visible) => {
            if (props.visible === undefined) {
                triggerVisible.value = visible;
            }
            emit('visibleChange', visible);
        };
        const getMenuElement = () => {
            var _a;
            const overlayElement = (_a = slots.overlay) === null || _a === void 0 ? void 0 : _a.call(slots);
            const extraOverlayProps = {
                prefixCls: `${props.prefixCls}-menu`,
                onClick,
                getPopupContainer: () => triggerRef.value.getPopupDomNode(),
            };
            return (<>
          {props.arrow && <div class={`${props.prefixCls}-arrow`}/>}
          {cloneElement(overlayElement, extraOverlayProps, false)}
        </>);
        };
        const minOverlayWidthMatchTrigger = computed(() => {
            const { minOverlayWidthMatchTrigger: matchTrigger = !props.alignPoint } = props;
            return matchTrigger;
        });
        const renderChildren = () => {
            var _a;
            const children = (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots);
            return triggerVisible.value && children
                ? cloneElement(children[0], { class: props.openClassName || `${props.prefixCls}-open` }, false)
                : children;
        };
        const triggerHideAction = computed(() => {
            if (!props.hideAction && props.trigger.indexOf('contextmenu') !== -1) {
                return ['click'];
            }
            return props.hideAction;
        });
        return () => {
            const { prefixCls, arrow, showAction, overlayStyle, trigger, placement, align, getPopupContainer, transitionName, animation, overlayClassName } = props, otherProps = __rest(props, ["prefixCls", "arrow", "showAction", "overlayStyle", "trigger", "placement", "align", "getPopupContainer", "transitionName", "animation", "overlayClassName"]);
            return (<Trigger {...otherProps} prefixCls={prefixCls} ref={triggerRef} popupClassName={classNames(overlayClassName, {
                    [`${prefixCls}-show-arrow`]: arrow,
                })} popupStyle={overlayStyle} builtinPlacements={placements} action={trigger} showAction={showAction} hideAction={triggerHideAction.value || []} popupPlacement={placement} popupAlign={align} popupTransitionName={transitionName} popupAnimation={animation} popupVisible={triggerVisible.value} stretch={minOverlayWidthMatchTrigger.value ? 'minWidth' : ''} onPopupVisibleChange={onVisibleChange} getPopupContainer={getPopupContainer} v-slots={{ popup: getMenuElement, default: renderChildren }}></Trigger>);
        };
    },
});
