import { warning } from '../../vc-util/warning';
import { cloneVNode, isVNode } from 'vue';
import { toArray } from './commonUtil';
function getKey(data, index) {
    const { key } = data;
    let value;
    if ('value' in data) {
        ({ value } = data);
    }
    if (key !== null && key !== undefined) {
        return key;
    }
    if (value !== undefined) {
        return value;
    }
    return `rc-index-key-${index}`;
}
export function fillFieldNames(fieldNames) {
    const { label, value, options } = fieldNames || {};
    return {
        label: label || 'label',
        value: value || 'value',
        options: options || 'options',
    };
}
/**
 * Flat options into flatten list.
 * We use `optionOnly` here is aim to avoid user use nested option group.
 * Here is simply set `key` to the index if not provided.
 */
export function flattenOptions(options, { fieldNames } = {}) {
    const flattenList = [];
    const { label: fieldLabel, value: fieldValue, options: fieldOptions, } = fillFieldNames(fieldNames);
    function dig(list, isGroupOption) {
        list.forEach(data => {
            const label = data[fieldLabel];
            if (isGroupOption || !(fieldOptions in data)) {
                // Option
                flattenList.push({
                    key: getKey(data, flattenList.length),
                    groupOption: isGroupOption,
                    data,
                    label,
                    value: data[fieldValue],
                });
            }
            else {
                // Option Group
                flattenList.push({
                    key: getKey(data, flattenList.length),
                    group: true,
                    data,
                    label,
                });
                dig(data[fieldOptions], true);
            }
        });
    }
    dig(options, false);
    return flattenList;
}
/**
 * Inject `props` into `option` for legacy usage
 */
function injectPropsWithOption(option) {
    const newOption = Object.assign({}, option);
    if (!('props' in newOption)) {
        Object.defineProperty(newOption, 'props', {
            get() {
                warning(false, 'Return type is option instead of Option instance. Please read value directly instead of reading from `props`.');
                return newOption;
            },
        });
    }
    return newOption;
}
export function findValueOption(values, options, { prevValueOptions = [] } = {}) {
    const optionMap = new Map();
    options.forEach(({ data, group, value }) => {
        if (!group) {
            // Check if match
            optionMap.set(value, data);
        }
    });
    return values.map(val => {
        let option = optionMap.get(val);
        // Fallback to try to find prev options
        if (!option) {
            option = Object.assign({}, prevValueOptions.find(opt => opt._INTERNAL_OPTION_VALUE_ === val));
        }
        return injectPropsWithOption(option);
    });
}
export const getLabeledValue = (value, { options, prevValueMap, labelInValue, optionLabelProp }) => {
    const item = findValueOption([value], options)[0];
    const result = {
        value,
    };
    const prevValItem = labelInValue ? prevValueMap.get(value) : undefined;
    if (prevValItem && typeof prevValItem === 'object' && 'label' in prevValItem) {
        result.label = prevValItem.label;
        if (item &&
            typeof prevValItem.label === 'string' &&
            typeof item[optionLabelProp] === 'string' &&
            prevValItem.label.trim() !== item[optionLabelProp].trim()) {
            warning(false, '`label` of `value` is not same as `label` in Select options.');
        }
    }
    else if (item && optionLabelProp in item) {
        if (Array.isArray(item[optionLabelProp])) {
            result.label = isVNode(item[optionLabelProp][0])
                ? cloneVNode(item[optionLabelProp][0])
                : item[optionLabelProp];
        }
        else {
            result.label = item[optionLabelProp];
        }
    }
    else {
        result.label = value;
        result.isCacheable = true;
    }
    // Used for motion control
    result.key = result.value;
    return result;
};
function toRawString(content) {
    return toArray(content)
        .map(item => {
        var _a, _b;
        if (isVNode(item)) {
            return ((_a = item === null || item === void 0 ? void 0 : item.el) === null || _a === void 0 ? void 0 : _a.innerText) || ((_b = item === null || item === void 0 ? void 0 : item.el) === null || _b === void 0 ? void 0 : _b.wholeText);
        }
        else {
            return item;
        }
    })
        .join('');
}
/** Filter single option if match the search text */
function getFilterFunction(optionFilterProp) {
    return (searchValue, option) => {
        const lowerSearchText = searchValue.toLowerCase();
        // Group label search
        if ('options' in option) {
            return toRawString(option.label).toLowerCase().includes(lowerSearchText);
        }
        // Option value search
        const rawValue = option[optionFilterProp];
        const value = toRawString(rawValue).toLowerCase();
        return value.includes(lowerSearchText);
    };
}
/** Filter options and return a new options by the search text */
export function filterOptions(searchValue, options, { optionFilterProp, filterOption, }) {
    const filteredOptions = [];
    let filterFunc;
    if (filterOption === false) {
        return [...options];
    }
    if (typeof filterOption === 'function') {
        filterFunc = filterOption;
    }
    else {
        filterFunc = getFilterFunction(optionFilterProp);
    }
    options.forEach(item => {
        // Group should check child options
        if ('options' in item) {
            // Check group first
            const matchGroup = filterFunc(searchValue, item);
            if (matchGroup) {
                filteredOptions.push(item);
            }
            else {
                // Check option
                const subOptions = item.options.filter(subItem => filterFunc(searchValue, subItem));
                if (subOptions.length) {
                    filteredOptions.push(Object.assign(Object.assign({}, item), { options: subOptions }));
                }
            }
            return;
        }
        if (filterFunc(searchValue, injectPropsWithOption(item))) {
            filteredOptions.push(item);
        }
    });
    return filteredOptions;
}
export function getSeparatedContent(text, tokens) {
    if (!tokens || !tokens.length) {
        return null;
    }
    let match = false;
    function separate(str, [token, ...restTokens]) {
        if (!token) {
            return [str];
        }
        const list = str.split(token);
        match = match || list.length > 1;
        return list
            .reduce((prevList, unitStr) => [...prevList, ...separate(unitStr, restTokens)], [])
            .filter(unit => unit);
    }
    const list = separate(text, tokens);
    return match ? list : null;
}
export function isValueDisabled(value, options) {
    const option = findValueOption([value], options)[0];
    return option.disabled;
}
/**
 * `tags` mode should fill un-list item into the option list
 */
export function fillOptionsWithMissingValue(options, value, optionLabelProp, labelInValue) {
    const values = toArray(value).slice().sort();
    const cloneOptions = [...options];
    // Convert options value to set
    const optionValues = new Set();
    options.forEach(opt => {
        if (opt.options) {
            opt.options.forEach((subOpt) => {
                optionValues.add(subOpt.value);
            });
        }
        else {
            optionValues.add(opt.value);
        }
    });
    // Fill missing value
    values.forEach(item => {
        const val = labelInValue
            ? item.value
            : item;
        if (!optionValues.has(val)) {
            cloneOptions.push(labelInValue
                ? {
                    [optionLabelProp]: item.label,
                    value: val,
                }
                : { value: val });
        }
    });
    return cloneOptions;
}
