export function isDisabled(dataNode, skipType) {
    if (!dataNode) {
        return true;
    }
    const { disabled, disableCheckbox } = dataNode.data.node;
    switch (skipType) {
        case 'checkbox':
            return disabled || disableCheckbox;
        default:
            return disabled;
    }
}
export default function useKeyValueMapping(cacheKeyMap, cacheValueMap) {
    const getEntityByKey = (key, skipType = 'select', ignoreDisabledCheck) => {
        const dataNode = cacheKeyMap.value.get(key);
        if (!ignoreDisabledCheck && isDisabled(dataNode, skipType)) {
            return null;
        }
        return dataNode;
    };
    const getEntityByValue = (value, skipType = 'select', ignoreDisabledCheck) => {
        const dataNode = cacheValueMap.value.get(value);
        if (!ignoreDisabledCheck && isDisabled(dataNode, skipType)) {
            return null;
        }
        return dataNode;
    };
    return [getEntityByKey, getEntityByValue];
}
