import PropTypes from '../_util/vue-types';
import omit from '../_util/omit';
export const inputDefaultValue = Symbol();
const inputProps = {
    id: PropTypes.string,
    prefixCls: PropTypes.string,
    inputPrefixCls: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: {
        type: [String, Number, Symbol],
        default: undefined,
    },
    placeholder: {
        type: [String, Number],
    },
    autocomplete: String,
    type: {
        type: String,
        default: 'text',
    },
    name: PropTypes.string,
    size: { type: String },
    disabled: PropTypes.looseBool,
    readonly: PropTypes.looseBool,
    addonBefore: PropTypes.any,
    addonAfter: PropTypes.any,
    prefix: PropTypes.any,
    suffix: PropTypes.any,
    autofocus: PropTypes.looseBool,
    allowClear: PropTypes.looseBool,
    lazy: PropTypes.looseBool.def(true),
    maxlength: PropTypes.number,
    loading: PropTypes.looseBool,
    bordered: PropTypes.looseBool,
    htmlSize: Number,
    onPressEnter: PropTypes.func,
    onKeydown: PropTypes.func,
    onKeyup: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    'onUpdate:value': PropTypes.func,
    valueModifiers: Object,
};
export default inputProps;
const textAreaProps = Object.assign(Object.assign({}, omit(inputProps, ['prefix', 'addonBefore', 'addonAfter', 'suffix'])), { autosize: { type: [Boolean, Object], default: undefined }, autoSize: { type: [Boolean, Object], default: undefined }, showCount: { type: [Boolean, Object] }, onResize: { type: Function }, onCompositionstart: PropTypes.func, onCompositionend: PropTypes.func, valueModifiers: Object });
export { textAreaProps };
