import { tuple } from '../_util/type';
import PropTypes from '../_util/vue-types';
import buttonTypes from '../button/buttonTypes';
const dropdownProps = () => ({
    arrow: PropTypes.looseBool,
    trigger: {
        type: [Array, String],
    },
    overlay: PropTypes.any,
    visible: PropTypes.looseBool,
    disabled: PropTypes.looseBool,
    align: { type: Object },
    getPopupContainer: PropTypes.func,
    prefixCls: PropTypes.string,
    transitionName: PropTypes.string,
    placement: PropTypes.oneOf(tuple('topLeft', 'topCenter', 'topRight', 'bottomLeft', 'bottomCenter', 'bottomRight')),
    overlayClassName: PropTypes.string,
    overlayStyle: PropTypes.style,
    forceRender: PropTypes.looseBool,
    mouseEnterDelay: PropTypes.number,
    mouseLeaveDelay: PropTypes.number,
    openClassName: PropTypes.string,
    minOverlayWidthMatchTrigger: PropTypes.looseBool,
    onVisibleChange: {
        type: Function,
    },
    'onUpdate:visible': {
        type: Function,
    },
});
const ButtonTypesProps = buttonTypes();
const dropdownButtonProps = () => (Object.assign(Object.assign({}, dropdownProps()), { type: ButtonTypesProps.type, size: PropTypes.oneOf(tuple('small', 'large')), htmlType: ButtonTypesProps.htmlType, href: PropTypes.string, disabled: PropTypes.looseBool, prefixCls: PropTypes.string, icon: PropTypes.any, title: PropTypes.string, onClick: {
        type: Function,
    } }));
export { dropdownProps, dropdownButtonProps };
export default dropdownProps;
