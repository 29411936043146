import { defineComponent, ref, computed } from 'vue';
import PropTypes from '../_util/vue-types';
import { filterEmpty, flattenChildren, isEmptyContent } from '../_util/props-util';
import ArrowLeftOutlined from '@ant-design/icons-vue/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons-vue/ArrowRightOutlined';
import Breadcrumb from '../breadcrumb';
import Avatar from '../avatar';
import TransButton from '../_util/transButton';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import { withInstall } from '../_util/type';
import useConfigInject from '../_util/hooks/useConfigInject';
import classNames from '../_util/classNames';
import ResizeObserver from '../vc-resize-observer';
export const pageHeaderProps = {
    backIcon: PropTypes.any,
    prefixCls: PropTypes.string,
    title: PropTypes.any,
    subTitle: PropTypes.any,
    breadcrumb: PropTypes.object,
    tags: PropTypes.any,
    footer: PropTypes.any,
    extra: PropTypes.any,
    avatar: PropTypes.object,
    ghost: PropTypes.looseBool,
    onBack: PropTypes.func,
};
const PageHeader = defineComponent({
    name: 'APageHeader',
    props: pageHeaderProps,
    emits: ['back'],
    slots: ['backIcon', 'avatar', 'breadcrumb', 'title', 'subTitle', 'tags', 'extra', 'footer'],
    setup(props, { emit, slots }) {
        const { prefixCls, direction, pageHeader } = useConfigInject('page-header', props);
        const compact = ref(false);
        const onResize = ({ width }) => {
            compact.value = width < 768;
        };
        const ghost = computed(() => { var _a, _b, _c; return (_c = (_a = props.ghost) !== null && _a !== void 0 ? _a : (_b = pageHeader.value) === null || _b === void 0 ? void 0 : _b.ghost) !== null && _c !== void 0 ? _c : true; });
        const getBackIcon = () => {
            var _a, _b, _c;
            return ((_c = (_a = props.backIcon) !== null && _a !== void 0 ? _a : (_b = slots.backIcon) === null || _b === void 0 ? void 0 : _b.call(slots)) !== null && _c !== void 0 ? _c : (direction.value === 'rtl' ? <ArrowRightOutlined /> : <ArrowLeftOutlined />));
        };
        const renderBack = (backIcon) => {
            if (!backIcon || !props.onBack) {
                return null;
            }
            return (<LocaleReceiver componentName="PageHeader" children={({ back }) => (<div class={`${prefixCls.value}-back`}>
              <TransButton onClick={e => {
                        emit('back', e);
                    }} class={`${prefixCls.value}-back-button`} aria-label={back}>
                {backIcon}
              </TransButton>
            </div>)}></LocaleReceiver>);
        };
        const renderBreadcrumb = () => {
            var _a;
            return props.breadcrumb ? <Breadcrumb {...props.breadcrumb}/> : (_a = slots.breadcrumb) === null || _a === void 0 ? void 0 : _a.call(slots);
        };
        const renderTitle = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            const { avatar } = props;
            const title = (_a = props.title) !== null && _a !== void 0 ? _a : (_b = slots.title) === null || _b === void 0 ? void 0 : _b.call(slots);
            const subTitle = (_c = props.subTitle) !== null && _c !== void 0 ? _c : (_d = slots.subTitle) === null || _d === void 0 ? void 0 : _d.call(slots);
            const tags = (_e = props.tags) !== null && _e !== void 0 ? _e : (_f = slots.tags) === null || _f === void 0 ? void 0 : _f.call(slots);
            const extra = (_g = props.extra) !== null && _g !== void 0 ? _g : (_h = slots.extra) === null || _h === void 0 ? void 0 : _h.call(slots);
            const headingPrefixCls = `${prefixCls.value}-heading`;
            const hasHeading = title || subTitle || tags || extra;
            // If there is nothing, return a null
            if (!hasHeading) {
                return null;
            }
            const backIcon = getBackIcon();
            const backIconDom = renderBack(backIcon);
            const hasTitle = backIconDom || avatar || hasHeading;
            return (<div class={headingPrefixCls}>
          {hasTitle && (<div class={`${headingPrefixCls}-left`}>
              {backIconDom}
              {avatar ? <Avatar {...avatar}/> : (_j = slots.avatar) === null || _j === void 0 ? void 0 : _j.call(slots)}
              {title && (<span class={`${headingPrefixCls}-title`} title={typeof title === 'string' ? title : undefined}>
                  {title}
                </span>)}
              {subTitle && (<span class={`${headingPrefixCls}-sub-title`} title={typeof subTitle === 'string' ? subTitle : undefined}>
                  {subTitle}
                </span>)}
              {tags && <span class={`${headingPrefixCls}-tags`}>{tags}</span>}
            </div>)}
          {extra && <span class={`${headingPrefixCls}-extra`}>{extra}</span>}
        </div>);
        };
        const renderFooter = () => {
            var _a, _b;
            const footer = (_a = props.footer) !== null && _a !== void 0 ? _a : filterEmpty((_b = slots.footer) === null || _b === void 0 ? void 0 : _b.call(slots));
            return isEmptyContent(footer) ? null : (<div class={`${prefixCls.value}-footer`}>{footer}</div>);
        };
        const renderChildren = (children) => {
            return <div class={`${prefixCls.value}-content`}>{children}</div>;
        };
        return () => {
            var _a, _b;
            const hasBreadcrumb = ((_a = props.breadcrumb) === null || _a === void 0 ? void 0 : _a.routes) || slots.breadcrumb;
            const hasFooter = props.footer || slots.footer;
            const children = flattenChildren((_b = slots.default) === null || _b === void 0 ? void 0 : _b.call(slots));
            const className = classNames(prefixCls.value, {
                'has-breadcrumb': hasBreadcrumb,
                'has-footer': hasFooter,
                [`${prefixCls.value}-ghost`]: ghost.value,
                [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
                [`${prefixCls.value}-compact`]: compact.value,
            });
            return (<ResizeObserver onResize={onResize}>
          <div class={className}>
            {renderBreadcrumb()}
            {renderTitle()}
            {children.length ? renderChildren(children) : null}
            {renderFooter()}
          </div>
        </ResizeObserver>);
        };
    },
});
export default withInstall(PageHeader);
