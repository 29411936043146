import PropTypes from '../../_util/vue-types';
const props = () => ({
    prefixCls: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.style,
    class: PropTypes.string,
    placement: {
        type: String,
    },
    wrapperClassName: PropTypes.string,
    level: { type: [String, Array] },
    levelMove: {
        type: [Number, Function, Array],
    },
    duration: PropTypes.string,
    ease: PropTypes.string,
    showMask: PropTypes.looseBool,
    maskClosable: PropTypes.looseBool,
    maskStyle: PropTypes.style,
    afterVisibleChange: PropTypes.func,
    keyboard: PropTypes.looseBool,
    contentWrapperStyle: PropTypes.style,
    autofocus: PropTypes.looseBool,
    open: PropTypes.looseBool,
});
const drawerProps = () => (Object.assign(Object.assign({}, props()), { forceRender: PropTypes.looseBool, getContainer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object,
        PropTypes.looseBool,
    ]) }));
const drawerChildProps = () => (Object.assign(Object.assign({}, props()), { getContainer: PropTypes.func, getOpenCount: PropTypes.func, scrollLocker: PropTypes.any, switchScrollingEffect: PropTypes.func }));
export { drawerProps, drawerChildProps };
