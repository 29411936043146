import { tuple } from '../_util/type';
import PropTypes from '../_util/vue-types';
const ButtonTypes = tuple('default', 'primary', 'ghost', 'dashed', 'link', 'text');
const ButtonShapes = tuple('circle', 'round');
const ButtonHTMLTypes = tuple('submit', 'button', 'reset');
export function convertLegacyProps(type) {
    if (type === 'danger') {
        return { danger: true };
    }
    return { type };
}
const buttonProps = () => ({
    prefixCls: PropTypes.string,
    type: PropTypes.oneOf(ButtonTypes),
    htmlType: PropTypes.oneOf(ButtonHTMLTypes).def('button'),
    shape: PropTypes.oneOf(ButtonShapes),
    size: {
        type: String,
    },
    loading: {
        type: [Boolean, Object],
        default: () => false,
    },
    disabled: PropTypes.looseBool,
    ghost: PropTypes.looseBool,
    block: PropTypes.looseBool,
    danger: PropTypes.looseBool,
    icon: PropTypes.any,
    href: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
    onClick: {
        type: Function,
    },
});
export default buttonProps;
