var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { computed, onMounted, ref, toRef, defineComponent } from 'vue';
import Tooltip from '../tooltip';
import abstractTooltipProps from '../tooltip/abstractTooltipProps';
import PropTypes from '../_util/vue-types';
import { initDefaultProps } from '../_util/props-util';
import { convertLegacyProps } from '../button/buttonTypes';
import ExclamationCircleFilled from '@ant-design/icons-vue/ExclamationCircleFilled';
import Button from '../button';
import { useLocaleReceiver } from '../locale-provider/LocaleReceiver';
import defaultLocale from '../locale-provider/default';
import { withInstall } from '../_util/type';
import useMergedState from '../_util/hooks/useMergedState';
import devWarning from '../vc-util/devWarning';
import KeyCode from '../_util/KeyCode';
import useConfigInject from '../_util/hooks/useConfigInject';
import classNames from '../_util/classNames';
import { getTransitionName } from '../_util/transition';
import { cloneVNodes } from '../_util/vnode';
import omit from '../_util/omit';
import { tooltipDefaultProps } from '../tooltip/Tooltip';
export const popconfirmProps = () => (Object.assign(Object.assign({}, abstractTooltipProps()), { prefixCls: PropTypes.string, content: PropTypes.any, title: PropTypes.any, okType: {
        type: String,
        default: 'primary',
    }, disabled: PropTypes.looseBool.def(false), okText: PropTypes.any, cancelText: PropTypes.any, icon: PropTypes.any, okButtonProps: PropTypes.object, cancelButtonProps: PropTypes.object }));
const Popconfirm = defineComponent({
    name: 'APopconfirm',
    props: initDefaultProps(popconfirmProps(), Object.assign(Object.assign({}, tooltipDefaultProps), { trigger: 'click', transitionName: 'zoom-big', align: () => ({}), placement: 'top', mouseEnterDelay: 0.1, mouseLeaveDelay: 0.1, arrowPointAtCenter: false, autoAdjustOverflow: true, okType: 'primary', disabled: false })),
    slots: ['title', 'content', 'okText', 'icon', 'cancelText', 'cancelButton', 'okButton'],
    emits: ['update:visible', 'confirm', 'cancel', 'visibleChange'],
    setup(props, { slots, emit, expose }) {
        onMounted(() => {
            devWarning(props.defaultVisible === undefined, 'Popconfirm', `'defaultVisible' is deprecated, please use 'v-model:visible'`);
        });
        const tooltipRef = ref();
        expose({
            getPopupDomNode: () => {
                var _a, _b;
                return (_b = (_a = tooltipRef.value) === null || _a === void 0 ? void 0 : _a.getPopupDomNode) === null || _b === void 0 ? void 0 : _b.call(_a);
            },
        });
        const [visible, setVisible] = useMergedState(false, {
            value: toRef(props, 'visible'),
            defaultValue: props.defaultVisible,
        });
        const settingVisible = (value, e) => {
            if (props.visible === undefined) {
                setVisible(value);
            }
            emit('update:visible', value);
            emit('visibleChange', value, e);
        };
        const onConfirm = (e) => {
            settingVisible(false, e);
            emit('confirm', e);
        };
        const onCancel = (e) => {
            settingVisible(false, e);
            emit('cancel', e);
        };
        const onKeyDown = (e) => {
            if (e.keyCode === KeyCode.ESC && visible) {
                settingVisible(false, e);
            }
        };
        const onVisibleChange = (value) => {
            const { disabled } = props;
            if (disabled) {
                return;
            }
            settingVisible(value);
        };
        const { prefixCls: prefixClsConfirm, configProvider } = useConfigInject('popconfirm', props);
        const rootPrefixCls = computed(() => configProvider.getPrefixCls());
        const popoverPrefixCls = computed(() => configProvider.getPrefixCls('popover'));
        const [popconfirmLocale] = useLocaleReceiver('Popconfirm', defaultLocale.Popconfirm);
        const renderOverlay = () => {
            var _a, _b, _c, _d;
            const { okButtonProps, cancelButtonProps, title = (_a = slots.title) === null || _a === void 0 ? void 0 : _a.call(slots), cancelText = (_b = slots.cancel) === null || _b === void 0 ? void 0 : _b.call(slots), okText = (_c = slots.okText) === null || _c === void 0 ? void 0 : _c.call(slots), okType, icon = (_d = slots.icon) === null || _d === void 0 ? void 0 : _d.call(slots), } = props;
            const { cancelButton, okButton } = slots;
            const cancelProps = Object.assign({ onClick: onCancel, size: 'small' }, cancelButtonProps);
            const okProps = Object.assign(Object.assign(Object.assign({ onClick: onConfirm }, convertLegacyProps(okType)), { size: 'small' }), okButtonProps);
            return (<div class={`${popoverPrefixCls.value}-inner-content`}>
          <div class={`${popoverPrefixCls.value}-message`}>
            {icon || <ExclamationCircleFilled />}
            <div class={`${popoverPrefixCls.value}-message-title`}>{title}</div>
          </div>
          <div class={`${popoverPrefixCls.value}-buttons`}>
            {cancelButton ? (cancelButton(cancelProps)) : (<Button {...cancelProps}>{cancelText || popconfirmLocale.value.cancelText}</Button>)}
            {okButton ? (okButton(okProps)) : (<Button {...okProps}>{okText || popconfirmLocale.value.okText}</Button>)}
          </div>
        </div>);
        };
        return () => {
            var _a;
            const { placement, overlayClassName } = props, restProps = __rest(props, ["placement", "overlayClassName"]);
            const otherProps = omit(restProps, [
                'title',
                'content',
                'cancelText',
                'okText',
                'onUpdate:visible',
            ]);
            const overlayClassNames = classNames(prefixClsConfirm.value, overlayClassName);
            return (<Tooltip {...otherProps} prefixCls={popoverPrefixCls.value} placement={placement} onVisibleChange={onVisibleChange} visible={visible.value} overlayClassName={overlayClassNames} transitionName={getTransitionName(rootPrefixCls.value, 'zoom-big', props.transitionName)} v-slots={{ title: renderOverlay }} ref={tooltipRef}>
          {cloneVNodes(((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)) || [], {
                    onKeydown: (e) => {
                        onKeyDown(e);
                    },
                }, false)}
        </Tooltip>);
        };
    },
});
export default withInstall(Popconfirm);
