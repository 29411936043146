import Trigger from '../../vc-trigger';
import { computed, defineComponent, onBeforeUnmount, ref, watch } from 'vue';
import { useInjectForceRender, useInjectMenu } from './hooks/useMenuContext';
import { placements, placementsRtl } from './placements';
import raf from '../../_util/raf';
import classNames from '../../_util/classNames';
import { getTransitionProps } from '../../_util/transition';
const popupPlacementMap = {
    horizontal: 'bottomLeft',
    vertical: 'rightTop',
    'vertical-left': 'rightTop',
    'vertical-right': 'leftTop',
};
export default defineComponent({
    name: 'PopupTrigger',
    inheritAttrs: false,
    props: {
        prefixCls: String,
        mode: String,
        visible: Boolean,
        // popup: React.ReactNode;
        popupClassName: String,
        popupOffset: Array,
        disabled: Boolean,
        onVisibleChange: Function,
    },
    slots: ['popup'],
    emits: ['visibleChange'],
    setup(props, { slots, emit }) {
        const innerVisible = ref(false);
        const { getPopupContainer, rtl, subMenuOpenDelay, subMenuCloseDelay, builtinPlacements, triggerSubMenuAction, isRootMenu, forceSubMenuRender, motion, defaultMotions, mode, } = useInjectMenu();
        const forceRender = useInjectForceRender();
        const placement = computed(() => rtl.value
            ? Object.assign(Object.assign({}, placementsRtl), builtinPlacements.value) : Object.assign(Object.assign({}, placements), builtinPlacements.value));
        const popupPlacement = computed(() => popupPlacementMap[props.mode]);
        const visibleRef = ref();
        watch(() => props.visible, visible => {
            raf.cancel(visibleRef.value);
            visibleRef.value = raf(() => {
                innerVisible.value = visible;
            });
        }, { immediate: true });
        onBeforeUnmount(() => {
            raf.cancel(visibleRef.value);
        });
        const onVisibleChange = (visible) => {
            emit('visibleChange', visible);
        };
        const style = ref({});
        const className = ref('');
        const mergedMotion = computed(() => {
            var _a, _b;
            const m = motion.value || ((_a = defaultMotions.value) === null || _a === void 0 ? void 0 : _a[mode.value]) || ((_b = defaultMotions.value) === null || _b === void 0 ? void 0 : _b.other);
            const res = typeof m === 'function' ? m(style, className) : m;
            return res ? getTransitionProps(res.name, { css: true }) : undefined;
        });
        return () => {
            const { prefixCls, popupClassName, mode, popupOffset, disabled } = props;
            return (<Trigger prefixCls={prefixCls} popupClassName={classNames(`${prefixCls}-popup`, {
                    [`${prefixCls}-rtl`]: rtl.value,
                }, popupClassName)} stretch={mode === 'horizontal' ? 'minWidth' : null} getPopupContainer={isRootMenu.value ? getPopupContainer.value : triggerNode => triggerNode.parentNode} builtinPlacements={placement.value} popupPlacement={popupPlacement.value} popupVisible={innerVisible.value} popupAlign={popupOffset && { offset: popupOffset }} action={disabled ? [] : [triggerSubMenuAction.value]} mouseEnterDelay={subMenuOpenDelay.value} mouseLeaveDelay={subMenuCloseDelay.value} onPopupVisibleChange={onVisibleChange} forceRender={forceRender || forceSubMenuRender.value} popupAnimation={mergedMotion.value} v-slots={{
                    popup: () => {
                        var _a;
                        return (_a = slots.popup) === null || _a === void 0 ? void 0 : _a.call(slots, { visible: innerVisible.value });
                    },
                    default: slots.default,
                }}></Trigger>);
        };
    },
});
