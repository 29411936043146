import { isCheckDisabled } from './valueUtil';
export const SHOW_ALL = 'SHOW_ALL';
export const SHOW_PARENT = 'SHOW_PARENT';
export const SHOW_CHILD = 'SHOW_CHILD';
export function formatStrategyKeys(keys, strategy, keyEntities) {
    const keySet = new Set(keys);
    if (strategy === SHOW_CHILD) {
        return keys.filter(key => {
            const entity = keyEntities[key];
            if (entity &&
                entity.children &&
                entity.children.every(({ node }) => isCheckDisabled(node) || keySet.has(node.key))) {
                return false;
            }
            return true;
        });
    }
    if (strategy === SHOW_PARENT) {
        return keys.filter(key => {
            const entity = keyEntities[key];
            const parent = entity ? entity.parent : null;
            if (parent && !isCheckDisabled(parent.node) && keySet.has(parent.node.key)) {
                return false;
            }
            return true;
        });
    }
    return keys;
}
