import { fillLegacyProps } from './legacyUtil';
import { flattenTreeData } from '../../vc-tree/utils/treeUtil';
export function toArray(value) {
    if (Array.isArray(value)) {
        return value;
    }
    return value !== undefined ? [value] : [];
}
/**
 * Fill `fieldNames` with default field names.
 *
 * @param fieldNames passed props
 * @param skipTitle Skip if no need fill `title`. This is useful since we have 2 name as same title level
 * @returns
 */
export function fillFieldNames(fieldNames, skipTitle = false) {
    const { label, value, children } = fieldNames || {};
    const filledNames = {
        value: value || 'value',
        children: children || 'children',
    };
    if (!skipTitle || label) {
        filledNames.label = label || 'label';
    }
    return filledNames;
}
export function findValueOption(values, options) {
    const optionMap = new Map();
    options.forEach(flattenItem => {
        const { data, value } = flattenItem;
        optionMap.set(value, data.node);
    });
    return values.map(val => fillLegacyProps(optionMap.get(val)));
}
export function isValueDisabled(value, options) {
    const option = findValueOption([value], options)[0];
    if (option) {
        return option.disabled;
    }
    return false;
}
export function isCheckDisabled(node) {
    return node.disabled || node.disableCheckbox || node.checkable === false;
}
function getLevel({ parent }) {
    let level = 0;
    let current = parent;
    while (current) {
        current = current.parent;
        level += 1;
    }
    return level;
}
/**
 * Before reuse `rc-tree` logic, we need to add key since TreeSelect use `value` instead of `key`.
 */
export function flattenOptions(options) {
    const typedOptions = options;
    // Add missing key
    function fillKey(list) {
        return (list || []).map(node => {
            const { value, key, children } = node;
            const clone = Object.assign(Object.assign({}, node), { key: 'key' in node ? key : value });
            if (children) {
                clone.children = fillKey(children);
            }
            return clone;
        });
    }
    const flattenList = flattenTreeData(fillKey(typedOptions), true, null);
    const cacheMap = new Map();
    const flattenDateNodeList = flattenList.map(option => {
        var _a;
        const { data, key, value } = option;
        const flattenNode = {
            key,
            value,
            data,
            level: getLevel(option),
            parentKey: (_a = option.parent) === null || _a === void 0 ? void 0 : _a.data.key,
        };
        cacheMap.set(key, flattenNode);
        return flattenNode;
    });
    // Fill parent
    flattenDateNodeList.forEach(flattenNode => {
        // eslint-disable-next-line no-param-reassign
        flattenNode.parent = cacheMap.get(flattenNode.parentKey);
    });
    return flattenDateNodeList;
}
function getDefaultFilterOption(optionFilterProp) {
    return (searchValue, dataNode) => {
        const value = dataNode[optionFilterProp];
        return String(value).toLowerCase().includes(String(searchValue).toLowerCase());
    };
}
/** Filter options and return a new options by the search text */
export function filterOptions(searchValue, options, { optionFilterProp, filterOption, }) {
    if (filterOption === false) {
        return options;
    }
    let filterOptionFunc;
    if (typeof filterOption === 'function') {
        filterOptionFunc = filterOption;
    }
    else {
        filterOptionFunc = getDefaultFilterOption(optionFilterProp);
    }
    function dig(list, keepAll = false) {
        return list
            .map(dataNode => {
            const { children } = dataNode;
            const match = keepAll || filterOptionFunc(searchValue, fillLegacyProps(dataNode));
            const childList = dig(children || [], match);
            if (match || childList.length) {
                return Object.assign(Object.assign({}, dataNode), { children: childList });
            }
            return null;
        })
            .filter(node => node);
    }
    return dig(options);
}
export function getRawValueLabeled(values, prevValue, getEntityByValue, getLabelProp) {
    const valueMap = new Map();
    toArray(prevValue).forEach(item => {
        if (item && typeof item === 'object' && 'value' in item) {
            valueMap.set(item.value, item);
        }
    });
    return values.map(val => {
        const item = { value: val };
        const entity = getEntityByValue(val, 'select', true);
        const label = entity ? getLabelProp(entity) : val;
        if (valueMap.has(val)) {
            const labeledValue = valueMap.get(val);
            item.label = 'label' in labeledValue ? labeledValue.label : label;
            if ('halfChecked' in labeledValue) {
                item.halfChecked = labeledValue.halfChecked;
            }
        }
        else {
            item.label = label;
        }
        return item;
    });
}
export function addValue(rawValues, value) {
    const values = new Set(rawValues);
    values.add(value);
    return Array.from(values);
}
export function removeValue(rawValues, value) {
    const values = new Set(rawValues);
    values.delete(value);
    return Array.from(values);
}
