var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getPosition, isTreeNode } from '../util';
import { warning } from '../../vc-util/warning';
import { camelize } from 'vue';
import { filterEmpty } from '../../_util/props-util';
import omit from '../../_util/omit';
export function getKey(key, pos) {
    if (key !== null && key !== undefined) {
        return key;
    }
    return pos;
}
export function fillFieldNames(fieldNames) {
    const { title, key, children } = fieldNames || {};
    return {
        title: title || 'title',
        key: key || 'key',
        children: children || 'children',
    };
}
/**
 * Warning if TreeNode do not provides key
 */
export function warningWithoutKey(treeData, fieldNames) {
    const keys = new Map();
    function dig(list, path = '') {
        (list || []).forEach(treeNode => {
            const key = treeNode[fieldNames.key];
            const children = treeNode[fieldNames.children];
            warning(key !== null && key !== undefined, `Tree node must have a certain key: [${path}${key}]`);
            const recordKey = String(key);
            warning(!keys.has(recordKey) || key === null || key === undefined, `Same 'key' exist in the Tree: ${recordKey}`);
            keys.set(recordKey, true);
            dig(children, `${path}${recordKey} > `);
        });
    }
    dig(treeData);
}
/**
 * Convert `children` of Tree into `treeData` structure.
 */
export function convertTreeToData(rootNodes) {
    function dig(node = []) {
        const treeNodes = filterEmpty(node);
        return treeNodes.map(treeNode => {
            var _a, _b, _c, _d;
            // Filter invalidate node
            if (!isTreeNode(treeNode)) {
                warning(!treeNode, 'Tree/TreeNode can only accept TreeNode as children.');
                return null;
            }
            const slots = treeNode.children || {};
            const key = treeNode.key;
            const props = {};
            for (const [k, v] of Object.entries(treeNode.props)) {
                props[camelize(k)] = v;
            }
            const { isLeaf, checkable, selectable, disabled, disableCheckbox } = props;
            // 默认值为 undefined
            const newProps = {
                isLeaf: isLeaf || isLeaf === '' || undefined,
                checkable: checkable || checkable === '' || undefined,
                selectable: selectable || selectable === '' || undefined,
                disabled: disabled || disabled === '' || undefined,
                disableCheckbox: disableCheckbox || disableCheckbox === '' || undefined,
            };
            const slotsProps = Object.assign(Object.assign({}, props), newProps);
            const { title = (_a = slots.title) === null || _a === void 0 ? void 0 : _a.call(slots, slotsProps), icon = (_b = slots.icon) === null || _b === void 0 ? void 0 : _b.call(slots, slotsProps), switcherIcon = (_c = slots.switcherIcon) === null || _c === void 0 ? void 0 : _c.call(slots, slotsProps) } = props, rest = __rest(props, ["title", "icon", "switcherIcon"]);
            const children = (_d = slots.default) === null || _d === void 0 ? void 0 : _d.call(slots);
            const dataNode = Object.assign(Object.assign(Object.assign({}, rest), { title,
                icon,
                switcherIcon,
                key,
                isLeaf }), newProps);
            const parsedChildren = dig(children);
            if (parsedChildren.length) {
                dataNode.children = parsedChildren;
            }
            return dataNode;
        });
    }
    return dig(rootNodes);
}
/**
 * Flat nest tree data into flatten list. This is used for virtual list render.
 * @param treeNodeList Origin data node list
 * @param expandedKeys
 * need expanded keys, provides `true` means all expanded (used in `rc-tree-select`).
 */
export function flattenTreeData(treeNodeList, expandedKeys, fieldNames) {
    const { title: fieldTitle, key: fieldKey, children: fieldChildren } = fillFieldNames(fieldNames);
    const expandedKeySet = new Set(expandedKeys === true ? [] : expandedKeys);
    const flattenList = [];
    function dig(list, parent = null) {
        return list.map((treeNode, index) => {
            const pos = getPosition(parent ? parent.pos : '0', index);
            const mergedKey = getKey(treeNode[fieldKey], pos);
            // Add FlattenDataNode into list
            const flattenNode = Object.assign(Object.assign({}, omit(treeNode, [fieldTitle, fieldKey, fieldChildren])), { title: treeNode[fieldTitle], key: mergedKey, parent,
                pos, children: null, data: treeNode, isStart: [...(parent ? parent.isStart : []), index === 0], isEnd: [...(parent ? parent.isEnd : []), index === list.length - 1] });
            flattenList.push(flattenNode);
            // Loop treeNode children
            if (expandedKeys === true || expandedKeySet.has(mergedKey)) {
                flattenNode.children = dig(treeNode[fieldChildren] || [], flattenNode);
            }
            else {
                flattenNode.children = [];
            }
            return flattenNode;
        });
    }
    dig(treeNodeList);
    return flattenList;
}
/**
 * Traverse all the data by `treeData`.
 * Please not use it out of the `rc-tree` since we may refactor this code.
 */
export function traverseDataNodes(dataNodes, callback, 
// To avoid too many params, let use config instead of origin param
config) {
    let mergedConfig = {};
    if (typeof config === 'object') {
        mergedConfig = config;
    }
    else {
        mergedConfig = { externalGetKey: config };
    }
    mergedConfig = mergedConfig || {};
    // Init config
    const { childrenPropName, externalGetKey, fieldNames } = mergedConfig;
    const { key: fieldKey, children: fieldChildren } = fillFieldNames(fieldNames);
    const mergeChildrenPropName = childrenPropName || fieldChildren;
    // Get keys
    let syntheticGetKey;
    if (externalGetKey) {
        if (typeof externalGetKey === 'string') {
            syntheticGetKey = (node) => node[externalGetKey];
        }
        else if (typeof externalGetKey === 'function') {
            syntheticGetKey = (node) => externalGetKey(node);
        }
    }
    else {
        syntheticGetKey = (node, pos) => getKey(node[fieldKey], pos);
    }
    // Process
    function processNode(node, index, parent) {
        const children = node ? node[mergeChildrenPropName] : dataNodes;
        const pos = node ? getPosition(parent.pos, index) : '0';
        // Process node if is not root
        if (node) {
            const key = syntheticGetKey(node, pos);
            const data = {
                node,
                index,
                pos,
                key,
                parentPos: parent.node ? parent.pos : null,
                level: parent.level + 1,
            };
            callback(data);
        }
        // Process children node
        if (children) {
            children.forEach((subNode, subIndex) => {
                processNode(subNode, subIndex, {
                    node,
                    pos,
                    level: parent ? parent.level + 1 : -1,
                });
            });
        }
    }
    processNode(null);
}
/**
 * Convert `treeData` into entity records.
 */
export function convertDataToEntities(dataNodes, { initWrapper, processEntity, onProcessFinished, externalGetKey, childrenPropName, fieldNames, } = {}, 
/** @deprecated Use `config.externalGetKey` instead */
legacyExternalGetKey) {
    // Init config
    const mergedExternalGetKey = externalGetKey || legacyExternalGetKey;
    const posEntities = {};
    const keyEntities = {};
    let wrapper = {
        posEntities,
        keyEntities,
    };
    if (initWrapper) {
        wrapper = initWrapper(wrapper) || wrapper;
    }
    traverseDataNodes(dataNodes, item => {
        const { node, index, pos, key, parentPos, level } = item;
        const entity = { node, index, key, pos, level };
        const mergedKey = getKey(key, pos);
        posEntities[pos] = entity;
        keyEntities[mergedKey] = entity;
        // Fill children
        entity.parent = posEntities[parentPos];
        if (entity.parent) {
            entity.parent.children = entity.parent.children || [];
            entity.parent.children.push(entity);
        }
        if (processEntity) {
            processEntity(entity, wrapper);
        }
    }, { externalGetKey: mergedExternalGetKey, childrenPropName, fieldNames });
    if (onProcessFinished) {
        onProcessFinished(wrapper);
    }
    return wrapper;
}
/**
 * Get TreeNode props with Tree props.
 */
export function getTreeNodeProps(key, { expandedKeys, selectedKeys, loadedKeys, loadingKeys, checkedKeys, halfCheckedKeys, dragOverNodeKey, dropPosition, keyEntities, }) {
    const entity = keyEntities[key];
    const treeNodeProps = {
        eventKey: key,
        expanded: expandedKeys.indexOf(key) !== -1,
        selected: selectedKeys.indexOf(key) !== -1,
        loaded: loadedKeys.indexOf(key) !== -1,
        loading: loadingKeys.indexOf(key) !== -1,
        checked: checkedKeys.indexOf(key) !== -1,
        halfChecked: halfCheckedKeys.indexOf(key) !== -1,
        pos: String(entity ? entity.pos : ''),
        parent: entity.parent,
        // [Legacy] Drag props
        // Since the interaction of drag is changed, the semantic of the props are
        // not accuracy, I think it should be finally removed
        dragOver: dragOverNodeKey === key && dropPosition === 0,
        dragOverGapTop: dragOverNodeKey === key && dropPosition === -1,
        dragOverGapBottom: dragOverNodeKey === key && dropPosition === 1,
    };
    return treeNodeProps;
}
export function convertNodePropsToEventData(props) {
    const { data, expanded, selected, checked, loaded, loading, halfChecked, dragOver, dragOverGapTop, dragOverGapBottom, pos, active, eventKey, } = props;
    const eventData = Object.assign(Object.assign({ dataRef: data }, data), { expanded,
        selected,
        checked,
        loaded,
        loading,
        halfChecked,
        dragOver,
        dragOverGapTop,
        dragOverGapBottom,
        pos,
        active,
        eventKey });
    if (!('props' in eventData)) {
        Object.defineProperty(eventData, 'props', {
            get() {
                warning(false, 'Second param return from event is node data instead of TreeNode instance. Please read value directly instead of reading from `props`.');
                return props;
            },
        });
    }
    return eventData;
}
