import Menu, { Item as MenuItem } from '../../menu';
import { defineComponent, inject, ref } from 'vue';
import MentionsContextKey from './MentionsContext';
import Spin from '../../spin';
function noop() { }
export default defineComponent({
    name: 'DropdownMenu',
    props: {
        prefixCls: String,
        options: {
            type: Array,
            default: () => [],
        },
    },
    slots: ['notFoundContent', 'option'],
    setup(props, { slots }) {
        const { activeIndex, setActiveIndex, selectOption, onFocus = noop, onBlur = noop, loading, } = inject(MentionsContextKey, {
            activeIndex: ref(),
            loading: ref(false),
        });
        return () => {
            var _a;
            const { prefixCls, options } = props;
            const activeOption = options[activeIndex.value] || {};
            return (<Menu prefixCls={`${prefixCls}-menu`} activeKey={activeOption.value} onSelect={({ key }) => {
                    const option = options.find(({ value }) => value === key);
                    selectOption(option);
                }} onBlur={onBlur} onFocus={onFocus}>
          {!loading.value &&
                    options.map((option, index) => {
                        var _a, _b;
                        const { value, disabled, label = option.value } = option;
                        return (<MenuItem key={value} disabled={disabled} onMouseenter={() => {
                                setActiveIndex(index);
                            }}>
                  {(_b = (_a = slots.option) === null || _a === void 0 ? void 0 : _a.call(slots, option)) !== null && _b !== void 0 ? _b : (typeof label === 'function' ? label({ value, disabled }) : label)}
                </MenuItem>);
                    })}
          {!loading.value && options.length === 0 ? (<MenuItem key="notFoundContent" disabled>
              {(_a = slots.notFoundContent) === null || _a === void 0 ? void 0 : _a.call(slots)}
            </MenuItem>) : null}
          {loading.value && (<MenuItem key="loading" disabled>
              <Spin size="small"/>
            </MenuItem>)}
        </Menu>);
        };
    },
});
