/**
 * Handle virtual list of the TreeNodes.
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { computed, defineComponent, ref, shallowRef, watch } from 'vue';
import VirtualList from '../vc-virtual-list';
import MotionTreeNode from './MotionTreeNode';
import { nodeListProps } from './props';
import { findExpandedKeys, getExpandRange } from './utils/diffUtil';
import { getTreeNodeProps, getKey } from './utils/treeUtil';
const HIDDEN_STYLE = {
    width: 0,
    height: 0,
    display: 'flex',
    overflow: 'hidden',
    opacity: 0,
    border: 0,
    padding: 0,
    margin: 0,
};
const noop = () => { };
export const MOTION_KEY = `RC_TREE_MOTION_${Math.random()}`;
const MotionNode = {
    key: MOTION_KEY,
};
export const MotionEntity = {
    key: MOTION_KEY,
    level: 0,
    index: 0,
    pos: '0',
    node: MotionNode,
};
const MotionFlattenData = {
    parent: null,
    children: [],
    pos: MotionEntity.pos,
    data: MotionNode,
    title: null,
    key: MOTION_KEY,
    /** Hold empty list here since we do not use it */
    isStart: [],
    isEnd: [],
};
/**
 * We only need get visible content items to play the animation.
 */
export function getMinimumRangeTransitionRange(list, virtual, height, itemHeight) {
    if (virtual === false || !height) {
        return list;
    }
    return list.slice(0, Math.ceil(height / itemHeight) + 1);
}
function itemKey(item) {
    const { key, pos } = item;
    return getKey(key, pos);
}
function getAccessibilityPath(item) {
    let path = String(item.key);
    let current = item;
    while (current.parent) {
        current = current.parent;
        path = `${current.key} > ${path}`;
    }
    return path;
}
export default defineComponent({
    name: 'NodeList',
    inheritAttrs: false,
    props: nodeListProps,
    setup(props, { expose, attrs }) {
        // =============================== Ref ================================
        const listRef = ref();
        const indentMeasurerRef = ref();
        expose({
            scrollTo: scroll => {
                listRef.value.scrollTo(scroll);
            },
            getIndentWidth: () => indentMeasurerRef.value.offsetWidth,
        });
        // ============================== Motion ==============================
        const transitionData = shallowRef(props.data);
        const transitionRange = shallowRef([]);
        const motionType = ref(null);
        function onMotionEnd() {
            transitionData.value = props.data;
            transitionRange.value = [];
            motionType.value = null;
            props.onListChangeEnd();
        }
        watch([() => [...props.expandedKeys], () => props.data], ([expandedKeys, data], [prevExpandedKeys, prevData]) => {
            const diffExpanded = findExpandedKeys(prevExpandedKeys, expandedKeys);
            if (diffExpanded.key !== null) {
                const { virtual, height, itemHeight } = props;
                if (diffExpanded.add) {
                    const keyIndex = prevData.findIndex(({ key }) => key === diffExpanded.key);
                    const rangeNodes = getMinimumRangeTransitionRange(getExpandRange(prevData, data, diffExpanded.key), virtual, height, itemHeight);
                    const newTransitionData = prevData.slice();
                    newTransitionData.splice(keyIndex + 1, 0, MotionFlattenData);
                    transitionData.value = newTransitionData;
                    transitionRange.value = rangeNodes;
                    motionType.value = 'show';
                }
                else {
                    const keyIndex = data.findIndex(({ key }) => key === diffExpanded.key);
                    const rangeNodes = getMinimumRangeTransitionRange(getExpandRange(data, prevData, diffExpanded.key), virtual, height, itemHeight);
                    const newTransitionData = data.slice();
                    newTransitionData.splice(keyIndex + 1, 0, MotionFlattenData);
                    transitionData.value = newTransitionData;
                    transitionRange.value = rangeNodes;
                    motionType.value = 'hide';
                }
            }
            else if (prevData !== data) {
                transitionData.value = data;
            }
        });
        // We should clean up motion if is changed by dragging
        watch(() => props.dragging, dragging => {
            if (!dragging) {
                onMotionEnd();
            }
        });
        const mergedData = computed(() => props.motion === undefined ? transitionData.value : props.data);
        return () => {
            const _a = Object.assign(Object.assign({}, props), attrs), { prefixCls, data, selectable, checkable, expandedKeys, selectedKeys, checkedKeys, loadedKeys, loadingKeys, halfCheckedKeys, keyEntities, disabled, dragging, dragOverNodeKey, dropPosition, motion, height, itemHeight, virtual, focusable, activeItem, focused, tabindex, onKeydown, onFocus, onBlur, onActiveChange, onListChangeStart, onListChangeEnd } = _a, domProps = __rest(_a, ["prefixCls", "data", "selectable", "checkable", "expandedKeys", "selectedKeys", "checkedKeys", "loadedKeys", "loadingKeys", "halfCheckedKeys", "keyEntities", "disabled", "dragging", "dragOverNodeKey", "dropPosition", "motion", "height", "itemHeight", "virtual", "focusable", "activeItem", "focused", "tabindex", "onKeydown", "onFocus", "onBlur", "onActiveChange", "onListChangeStart", "onListChangeEnd"]);
            const treeNodeRequiredProps = {
                expandedKeys,
                selectedKeys,
                loadedKeys,
                loadingKeys,
                checkedKeys,
                halfCheckedKeys,
                dragOverNodeKey,
                dropPosition,
                keyEntities,
            };
            return (<>
          {focused && activeItem && (<span style={HIDDEN_STYLE} aria-live="assertive">
              {getAccessibilityPath(activeItem)}
            </span>)}

          <div>
            <input style={HIDDEN_STYLE} disabled={focusable === false || disabled} tabindex={focusable !== false ? tabindex : null} onKeydown={onKeydown} onFocus={onFocus} onBlur={onBlur} value="" onChange={noop} aria-label="for screen reader"/>
          </div>

          <div class={`${prefixCls}-treenode`} aria-hidden style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    visibility: 'hidden',
                    height: 0,
                    overflow: 'hidden',
                }}>
            <div class={`${prefixCls}-indent`}>
              <div ref={indentMeasurerRef} class={`${prefixCls}-indent-unit`}/>
            </div>
          </div>

          <VirtualList {...domProps} data={mergedData.value} itemKey={itemKey} height={height} fullHeight={false} virtual={virtual} itemHeight={itemHeight} prefixCls={`${prefixCls}-list`} ref={listRef} children={(treeNode) => {
                    const { pos } = treeNode, restProps = __rest(treeNode.data, []), { title, key, isStart, isEnd } = treeNode;
                    const mergedKey = getKey(key, pos);
                    delete restProps.key;
                    delete restProps.children;
                    const treeNodeProps = getTreeNodeProps(mergedKey, treeNodeRequiredProps);
                    return (<MotionTreeNode {...restProps} {...treeNodeProps} title={title} active={!!activeItem && key === activeItem.key} pos={pos} data={treeNode.data} isStart={isStart} isEnd={isEnd} motion={motion} motionNodes={key === MOTION_KEY ? transitionRange.value : null} motionType={motionType.value} onMotionStart={onListChangeStart} onMotionEnd={onMotionEnd} treeNodeRequiredProps={treeNodeRequiredProps} onMousemove={() => {
                            onActiveChange(null);
                        }}/>);
                }}></VirtualList>
        </>);
        };
    },
});
